import { memo } from 'react'
import { OrSwatchCardsSlider } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { normalizeAsset } from '@utils/normalize'
import { ContentfulObjFlooringBorderColor, OrderRugStepperIndexes } from 'types'
import {
  COMMON_STEP_SWATCH_SLIDES,
  MAX_WIDTH_TABLET_SCREEN_SIZE,
  PREVIEW_CONFIRM_STEP_SWATCH_SLIDES,
} from './weave-color-options'

interface BorderColorOptionsProps {
  options: ContentfulObjFlooringBorderColor[]
}

export const BorderColorOptions = memo(({ options = [] }: BorderColorOptionsProps) => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, border } = state

  return (
    <div
      className={`w-full ${
        activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM
          ? 'md:max-w-md lg:max-w-2xl xl:max-w-3xl'
          : 'max-w-3xl xl:max-w-4xl'
      }`}
    >
      <OrSwatchCardsSlider
        breakpoints={{
          [MAX_WIDTH_TABLET_SCREEN_SIZE]: {
            slidesPerView:
              activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM
                ? PREVIEW_CONFIRM_STEP_SWATCH_SLIDES
                : COMMON_STEP_SWATCH_SLIDES,
          },
        }}
        swatchCardSelected={border.borderColor?.CONTENTFUL_ID ?? ''}
        swatchCardHandler={(option) => {
          const foundBorderColor = options.find((optionItem) => optionItem.CONTENTFUL_ID === option.id)

          if (foundBorderColor) {
            dispatch({
              type: RugBuilderActionTypes.SET_BORDER_COLOR,
              borderColorPayload: foundBorderColor,
            })

            dispatch({
              type: RugBuilderActionTypes.SET_BORDER_WIDTH,
              borderWidthPayload: undefined,
            })

            if (border.borderMaterial && !border.borderMaterial.automiter) {
              dispatch({
                type: RugBuilderActionTypes.SET_CORNER_STYLE,
                cornerStylePayload: undefined,
              })
            }
          }
        }}
        options={options.map(({ CONTENTFUL_ID, borderColorName, images, subheaderRugBuilder }) => ({
          id: CONTENTFUL_ID,
          title: borderColorName,
          description: subheaderRugBuilder ?? '',
          disabled: false,
          selected: false,
          image:
            images && Array.isArray(images) && images.length > 0
              ? normalizeAsset({ asset: images[0] })
              : { src: '/images/image-coming-soon.jpg', alt: '', caption: undefined },
          clickHandler: () => ({}),
        }))}
      />
    </div>
  )
})
