import { useEffect, useState } from 'react'

import { FurnitureProductActionTypes, ObjFabricState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ColorOptions, MlProductOptionsCollapse } from '@components/product-detail-options'
import {
  ContentfulObjFurnitureMainCushion,
  ContentfulObjFurnitureProduct,
  ContentType,
  MAIN_CUSHIONS_DEFAULT_TITLE,
} from 'types'

interface MainCushionsProps {
  initialToggleCollapse: boolean
  mainCushionsTitle: ContentfulObjFurnitureProduct['mainCushionsTitle']
  mainCushionsUniqueSelection: ContentfulObjFurnitureProduct['mainCushionsUniqueSelection']
  mainCushions: ContentfulObjFurnitureMainCushion[]
  collapsePosition: string[]
  requiredQuantity: number
}

export const MainCushions = ({
  initialToggleCollapse,
  mainCushionsTitle,
  mainCushions = [],
  mainCushionsUniqueSelection = false,
  collapsePosition,
  requiredQuantity,
}: MainCushionsProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const { basePrice, mainCushionFabric } = state

  const [uniqueOptions, setUniqueOptions] = useState<ObjFabricState[]>([])
  const [repeatedOptions, setRepeatedOptions] = useState<ObjFabricState[]>([])
  const [updateUniqueOptions, setUpdateUniqueOptions] = useState(false)

  useEffect(() => {
    const fabrics: ObjFabricState[] = []

    mainCushions.forEach((mainCushion) => {
      if (Array.isArray(mainCushion.fabricGrades) && mainCushion.fabricGrades.length > 0) {
        mainCushion.fabricGrades.forEach((fabricGrade) => {
          if (Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0) {
            const fabricsUniqueId: ObjFabricState[] = fabricGrade.fabrics.map((fabric) => ({
              ...fabric,
              price: ((fabric as ObjFabricState).price ?? 0) * requiredQuantity,
              uniqueID: `${mainCushion.CONTENTFUL_ID}-${fabricGrade.CONTENTFUL_ID}-${fabric.CONTENTFUL_ID}`,
            }))

            fabrics.push(...fabricsUniqueId)
          }
        })
      }
    })

    // This validation only apply for CF mainCushionsUniqueSelection field
    if (mainCushionsUniqueSelection) {
      const fabricCounts = new Map<string, number>()

      fabrics.forEach((obj) => {
        if (fabricCounts.has(obj.CONTENTFUL_ID)) {
          fabricCounts.set(obj.CONTENTFUL_ID, (fabricCounts.get(obj.CONTENTFUL_ID) ?? 0) + 1)
        } else {
          fabricCounts.set(obj.CONTENTFUL_ID, 1)
        }
      })

      const uniqueFabrics: ObjFabricState[] = []

      fabricCounts.forEach((count, id) => {
        // Inner join fabrics > 1
        if (count > 1) {
          const foundFabric = fabrics.find((obj) => obj.CONTENTFUL_ID === id)

          if (foundFabric) {
            // Set the sum prices of repeated fabrics
            const foundFabricPrice = fabrics
              .filter(({ CONTENTFUL_ID }) => foundFabric.CONTENTFUL_ID === CONTENTFUL_ID)
              .reduce((acc, curr) => {
                return acc + (curr.price ?? 0)
              }, 0)

            uniqueFabrics.push({ ...foundFabric, price: foundFabricPrice })
          }
        }
      })

      setUniqueOptions(uniqueFabrics)
      setRepeatedOptions(fabrics)
    } else {
      setUniqueOptions(fabrics)
    }
    setUpdateUniqueOptions(true)
  }, [mainCushions, mainCushionsUniqueSelection, requiredQuantity])

  // Auto-select the first cushion
  useEffect(() => {
    if (updateUniqueOptions && uniqueOptions.length > 0) {
      // Obtain the min-price cushion
      const cushionMinPrice = [...uniqueOptions].sort((a, b) => (a.price ?? 0) - (b.price ?? 0))

      if (cushionMinPrice[0].price && cushionMinPrice[0].orderSku) {
        dispatch({
          type: FurnitureProductActionTypes.SET_MAIN_CUSHION,
          mainCushionFabric: uniqueOptions[0],
        })

        dispatch({
          type: FurnitureProductActionTypes.SET_BASE_CUSHION_MIN_PRICE,
          cushionMinPrice: cushionMinPrice[0].price,
        })

        dispatch({
          type: FurnitureProductActionTypes.SET_MAIN_CUSHION_PRICE_NOT_FOUND,
          mainCushionPriceNotFound: false,
        })

        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      } else if (!basePrice) {
        dispatch({
          type: FurnitureProductActionTypes.SET_MAIN_CUSHION_PRICE_NOT_FOUND,
          mainCushionPriceNotFound: true,
        })
      }

      setUpdateUniqueOptions(false)
    }
  }, [dispatch, updateUniqueOptions, uniqueOptions, state.uniqueMainCushionFabrics, basePrice])

  // Send unique-cushion based cushion selected to furniture-context
  useEffect(() => {
    if (mainCushionsUniqueSelection) {
      if (mainCushionFabric && repeatedOptions.length > 0) {
        dispatch({
          type: FurnitureProductActionTypes.SET_UNIQUE_MAIN_CUSHION_FABRICS,
          uniqueMainCushionFabrics: repeatedOptions.filter(
            ({ CONTENTFUL_ID }) => mainCushionFabric.CONTENTFUL_ID === CONTENTFUL_ID,
          ),
        })
      }
    }
  }, [dispatch, mainCushionFabric, mainCushionsUniqueSelection, repeatedOptions])

  return (
    <MlProductOptionsCollapse
      index={collapsePosition.findIndex((option) => option === 'mainCushions') + 1}
      isRequired={true}
      initialToggleCollapse={initialToggleCollapse}
      name={mainCushionsTitle ?? MAIN_CUSHIONS_DEFAULT_TITLE}
      optionSelected={mainCushionFabric?.fabricName}
    >
      <ColorOptions contentType={ContentType.OBJ_FURNITURE_MAIN_CUSHION} options={uniqueOptions} />
    </MlProductOptionsCollapse>
  )
}
