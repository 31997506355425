import { memo, useEffect, useMemo, useState } from 'react'

import { ObjFabricState } from '@context/furniture-product'
import { MlProductOptionsCollapse, OptionalCushionGroupColorOptions } from '@components/product-detail-options'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { useMediaQuery } from '@hooks/use-media-query'
import { ContentfulObjFurnitureOptionalCushionGroup } from 'types'

const getCheckedOptionalCushionsGroupFabric = ({
  options,
  openPopover,
  optionalCushionsGroupFabricSelected,
}: {
  openPopover: boolean
  optionalCushionsGroupFabricSelected?: ObjFabricState
  options: ObjFabricState[]
}) => {
  const optionalCushionGroupFabricIndex = openPopover
    ? options.findIndex(({ uniqueID, CONTENTFUL_ID }) =>
        uniqueID
          ? uniqueID === optionalCushionsGroupFabricSelected?.uniqueID
          : CONTENTFUL_ID === optionalCushionsGroupFabricSelected?.CONTENTFUL_ID,
      )
    : undefined
  // Convert the optionalCushionsGroupFabricSelected to popover obj
  const popoverOptionalCushionGroupFabricObj: ObjFabricState | undefined = optionalCushionsGroupFabricSelected
    ? {
        ...optionalCushionsGroupFabricSelected,
        uniqueID: `${
          optionalCushionsGroupFabricSelected?.uniqueID
            ? `${optionalCushionsGroupFabricSelected?.uniqueID}-popover`
            : `${optionalCushionsGroupFabricSelected?.CONTENTFUL_ID}-popover`
        }`,
        isPopover: true,
      }
    : undefined

  return { optionalCushionGroupFabricIndex, popoverOptionalCushionGroupFabricObj }
}

interface OptionalCushionsGroupProps {
  initialToggleCollapse: boolean
  optionalCushionGroup: ContentfulObjFurnitureOptionalCushionGroup
  position: number
  collapsePosition: string[]
}

export const OptionalCushionsGroup = memo(
  ({ initialToggleCollapse, optionalCushionGroup, position, collapsePosition }: OptionalCushionsGroupProps) => {
    const { state } = useFurnitureProduct()
    const { optionalCushionGroupFabrics } = state

    const isDesktop = useMediaQuery('(min-width: 1280px)')

    const [options, setOptions] = useState<ObjFabricState[]>([])
    const [openPopover, setOpenPopover] = useState(false)

    const hasOptionalCushions =
      optionalCushionGroup &&
      Array.isArray(optionalCushionGroup.optionalCushions) &&
      optionalCushionGroup.optionalCushions.length > 0

    useEffect(() => {
      const fabrics: ObjFabricState[] = []
      if (hasOptionalCushions) {
        optionalCushionGroup?.optionalCushions.forEach((optionalCushion) => {
          if (Array.isArray(optionalCushion.fabricGrades) && optionalCushion.fabricGrades.length > 0) {
            optionalCushion.fabricGrades.forEach((fabricGrade) => {
              if (Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0) {
                const normalizeFabrics = fabricGrade.fabrics.map((fabric) => ({
                  ...fabric,
                  cushionGroupName: optionalCushionGroup.optionalCushionGroupName,
                  cushionGroupId: optionalCushionGroup.CONTENTFUL_ID,
                  uniqueID: `${optionalCushionGroup.CONTENTFUL_ID}-${optionalCushion.CONTENTFUL_ID}-${fabricGrade.CONTENTFUL_ID}-${fabric.CONTENTFUL_ID}`,
                }))
                fabrics.push(...normalizeFabrics)
              }
            })
          }
        })
        setOptions(fabrics)
      }
    }, [
      hasOptionalCushions,
      optionalCushionGroup.CONTENTFUL_ID,
      optionalCushionGroup.optionalCushionGroupName,
      optionalCushionGroup?.optionalCushions,
    ])

    const optionalCushionsGroupFabricSelected = useMemo(
      () =>
        optionalCushionGroupFabrics
          ? options.find((option) =>
              optionalCushionGroupFabrics.find((optionalCushion) => optionalCushion.uniqueID === option.uniqueID),
            )
          : undefined,
      [optionalCushionGroupFabrics, options],
    )

    const { optionalCushionGroupFabricIndex, popoverOptionalCushionGroupFabricObj } = useMemo(
      () => getCheckedOptionalCushionsGroupFabric({ openPopover, optionalCushionsGroupFabricSelected, options }),
      [openPopover, optionalCushionsGroupFabricSelected, options],
    )

    return (
      <MlProductOptionsCollapse
        initialToggleCollapse={position === 0 && initialToggleCollapse}
        index={collapsePosition.findIndex((option) => option === `optional-cushion-group${position + 1}`) + 1}
        isRequired={false}
        name={optionalCushionGroup.optionalCushionGroupName}
        optionSelected={optionalCushionsGroupFabricSelected?.fabricName}
      >
        <ul className="grid grid-cols-2 items-center xl:grid-cols-3 gap-4 p-4 w-full max-h-[460px] md:max-h-96 xl:max-h-[500px] overflow-y-scroll">
          {(typeof optionalCushionGroupFabricIndex === 'number' && popoverOptionalCushionGroupFabricObj && !isDesktop
            ? [
                ...options.slice(
                  0,
                  optionalCushionGroupFabricIndex % 2 === 0
                    ? optionalCushionGroupFabricIndex
                    : optionalCushionGroupFabricIndex - 1,
                ),
                popoverOptionalCushionGroupFabricObj,
                ...options.slice(
                  optionalCushionGroupFabricIndex % 2 === 0
                    ? optionalCushionGroupFabricIndex
                    : optionalCushionGroupFabricIndex - 1,
                ),
              ].filter(Boolean)
            : options
          ).map((option) => (
            <OptionalCushionGroupColorOptions
              key={option.uniqueID}
              fabric={option}
              hasQuantity={optionalCushionGroup.hasQuantity}
              onOpenPopover={() => setOpenPopover(true)}
              onClosePopover={() => setOpenPopover(false)}
            />
          ))}
        </ul>
      </MlProductOptionsCollapse>
    )
  },
)
