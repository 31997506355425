import { ChangeEvent, FormEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import {
  AtButton,
  AtButtonSize,
  AtButtonState,
  AtButtonVariant,
  AtInfoItem,
  AtInfoItemVariant,
  OrModal,
} from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes, FurnitureProductState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ShoppingInfo } from '@components/common-product-detail'
import { Quantity } from '@components/product-detail-options'
import { getModalOrderInfo } from '@templates/tm-product-detail/utils'
import { getPosition } from '@utils/get-collapse-position'
import { formatPrice } from '@utils/price'
import { Addons, AddonsGroup, Combos, FabricGrades, MainCushions, OptionalCushionsGroup, PresetFabrics } from '.'
import {
  ADDONS_DEFAULT_TITLE,
  ContentfulObjFurnitureProduct,
  ErrorFurniturePrices,
  FABRIC_GRADES_DEFAULT_TITLE,
  FurniturePrices,
  MAIN_CUSHIONS_DEFAULT_TITLE,
} from 'types'
import { normalizeAsset, normalizeInfoItem } from '@utils/normalize'
import { useCart, useFurniturePriceCalculator, useMediaQuery, useModal } from 'hooks'
import { FurnitureCartItem } from '@services/cart'
import { toast } from 'react-hot-toast'

import { ProductDescription } from '@components/furniture-product-detail'
import { useSessionStorage } from '@hooks/use-session-storage'
import aa from 'search-insights'
import { environment } from '@config/environment'
import { sendCartAnalyticsEvent } from '@utils/analytics/events/cart/send-event'
import { CartEventTypes } from '../../types/analytics/analytics-data'

export enum ComboLength {
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3,
  QUATERNARY = 4,
}

interface ProductDetailCollapsesProps {
  product: ContentfulObjFurnitureProduct
  productPrices: FurniturePrices | ErrorFurniturePrices
}

export const ProductDetailCollapses = memo(({ product, productPrices }: ProductDetailCollapsesProps) => {
  const {
    productName,
    hasPresetFabrics,
    fabricGrades,
    fabricGradesTitle,
    combos,
    useGroupedOptions,
    optionalCushionsGroup,
    mainCushionsTitle,
    mainCushionsUniqueSelection,
    mainCushions,
    addonsTitle,
    addons,
    addonGroups,
    images,
  } = product

  const router = useRouter()
  const { asPath } = router
  const isMobile = useMediaQuery('(max-width: 1279px)')
  const [previousRoute] = useSessionStorage('x-previousTitleRoute', router.asPath)
  const [productIndexId, _, removeStoredProductIndexID] = useSessionStorage<string | null>('productIndexID', null)

  const { state, dispatch } = useFurnitureProduct()
  const {
    basePrice,
    startingPrice,
    cushionMinPrice,
    quantity,
    totalPrice,
    orderSku,
    presetFabricGrade,
    fabric,
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
    mainCushionFabric,
    uniqueMainCushionFabrics,
    optionalCushionGroupFabrics,
    addonsFabricOrFrameSlingCol,
    addonsGroupFabricOrFrameSlingCol,
    isLoadingPrices,
    basePriceNotFound,
    mainCushionPriceNotFound,
    optionalCushionGroupPriceNotFound,
    addonPriceNotFound,
    addonsGroupPriceNotFound,
    clearSelectionsDisabled,
    algoliaQueryId,
  } = state

  const { addFurnitureProductToCart } = useCart()

  const { isModalOpen, setIsModalOpen, modalInfo, handlerClick } = useModal()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCartPageLoading, setCartPageLoading] = useState(false)

  const hasFabricGrades = Array.isArray(fabricGrades) && fabricGrades.length > 0
  const hasCombos = Array.isArray(combos) && combos.length > 0
  const requiredQuantity = productPrices && ((productPrices as FurniturePrices).requiredQuantity ?? 1)

  useFurniturePriceCalculator({
    hasPresetFabrics,
    productPrices,
    hasFabricGrades,
    hasCombos,
    useGroupedOptions,
  })

  const hasPrimaryCombo = hasCombos && combos.length === ComboLength.PRIMARY
  const hasSecondaryCombo = hasCombos && combos.length === ComboLength.SECONDARY
  const hasTertiaryCombo = hasCombos && combos.length === ComboLength.TERTIARY
  const hasQuaternaryCombo = hasCombos && combos.length === ComboLength.QUATERNARY

  const hasMainCushions = Array.isArray(mainCushions) && mainCushions.length > 0
  const hasOptionalCushionsGroup = Array.isArray(optionalCushionsGroup) && optionalCushionsGroup.length > 0
  const hasAddons = Array.isArray(addons) && addons.length > 0
  const hasAddonsGroup = Array.isArray(addonGroups) && addonGroups.length > 0

  const collapsePosition = useMemo(
    () =>
      getPosition({
        hasPresetFabrics,
        hasFabricGrades,
        hasCombos,
        hasMainCushions,
        hasAddonsGroup,
        addonGroups,
        combos,
        hasOptionalCushionsGroup,
        optionalCushionsGroup,
        hasAddons,
      }),
    [
      combos,
      hasCombos,
      hasFabricGrades,
      hasMainCushions,
      hasOptionalCushionsGroup,
      hasPresetFabrics,
      optionalCushionsGroup,
      hasAddonsGroup,
      addonGroups,
      hasAddons,
    ],
  )

  const isFabricGradesDisabled = () => {
    if (hasPresetFabrics && hasFabricGrades) {
      return !!presetFabricGrade
    }
    if (!hasPresetFabrics && hasFabricGrades) {
      return !!fabric
    }
    return true
  }
  const isCombosDisabled = useCallback(() => {
    if (hasPrimaryCombo) {
      return !!primaryComboFrameSlingCol
    } else if (hasSecondaryCombo) {
      return !!secondaryComboFrameSlingCol
    } else if (hasTertiaryCombo) {
      return !!tertiaryComboFrameSlingCol
    } else if (hasQuaternaryCombo) {
      return !!quaternaryComboFrameSlingCol
    }
    return true
  }, [
    hasPrimaryCombo,
    hasQuaternaryCombo,
    hasSecondaryCombo,
    hasTertiaryCombo,
    primaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
  ])

  const isMainCushionsDisabled = useCallback(() => {
    const hasMainCushionFabric =
      hasMainCushions &&
      mainCushions.some(
        (mainCushion) =>
          Array.isArray(mainCushion.fabricGrades) &&
          mainCushion.fabricGrades.length > 0 &&
          mainCushion.fabricGrades.some(
            (fabricGrades) => Array.isArray(fabricGrades.fabrics) && fabricGrades.fabrics.length > 0,
          ),
      )

    if (hasMainCushionFabric) {
      return !!mainCushionFabric
    }
    return true
  }, [hasMainCushions, mainCushionFabric, mainCushions])

  const isAddonsGroupDisabled = useCallback(() => {
    const hasAddonGroupsRequired = hasAddonsGroup && addonGroups.some((addonGroup) => addonGroup.required)
    if (hasAddonGroupsRequired) {
      const filteredAddonGroupsRequired = addonGroups.filter((addonGroup) => addonGroup.required)
      if (addonsGroupFabricOrFrameSlingCol && Object.keys(addonsGroupFabricOrFrameSlingCol).length > 0) {
        const filteredAddons = filteredAddonGroupsRequired
          .map((addonGroup) =>
            addonGroup?.addons?.find((addon) =>
              addonsGroupFabricOrFrameSlingCol[addonGroup.CONTENTFUL_ID]?.find(
                (selectedAddon) => selectedAddon.CONTENTFUL_ID === addon.CONTENTFUL_ID && addonGroup.required,
              ),
            ),
          )
          .filter(Boolean)
        return filteredAddons.length === filteredAddonGroupsRequired.length
      }
      return false
    }

    return true
  }, [addonGroups, addonsGroupFabricOrFrameSlingCol, hasAddonsGroup])

  const isPricesDisabled = !!totalPrice

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (
        product.callForQuote ||
        basePriceNotFound ||
        mainCushionPriceNotFound ||
        optionalCushionGroupPriceNotFound ||
        addonPriceNotFound ||
        addonsGroupPriceNotFound
      )
        return router.push('/customer-service')

      // Unique order-sku generated to products with fabric-grade/preset-fabrics or combos
      // provided by the product prices api
      if (!orderSku) return

      setIsSubmitting(true)

      const childItems: FurnitureCartItem[] = []
      if (fabric) {
        if (!fabric.sku) {
          // eslint-disable-next-line no-console
          console.error('Missing sku for fabric.')
          return toast.error('An unexpected error occurred. Please try again later')
        }
        childItems.push({
          sku: fabric.sku,
          quantity: quantity,
          description: fabric.fabricName ?? 'Fabric',
          details: fabric.description ? documentToPlainTextString(fabric.description) : fabric.fabricName,
          image_src:
            Array.isArray(fabric.images) && fabric.images.length > 0
              ? normalizeAsset({ asset: fabric.images[0] }).src
              : '',
          image_alt:
            Array.isArray(fabric.images) && fabric.images.length > 0
              ? normalizeAsset({ asset: fabric.images[0] }).alt
              : '',
          metadata: {
            index: productIndexId,
            brand: product.vendor?.vendorName ?? '',
            category: product.category ?? '',
            category2: product.category2 ?? '',
            itemListName: previousRoute,
            contentful_id: fabric.CONTENTFUL_ID,
            query_id: algoliaQueryId ?? '',
          },
        })
      }

      if (primaryComboFrameSlingCol) {
        if (!primaryComboFrameSlingCol.sku) {
          // eslint-disable-next-line no-console
          console.error('Missing sku for primary combo.')
          return toast.error('An unexpected error occurred. Please try again later')
        }

        childItems.push({
          sku: primaryComboFrameSlingCol.sku,
          quantity: quantity,
          description: primaryComboFrameSlingCol.frameSlingColName ?? 'Primary Combo',
          details: primaryComboFrameSlingCol.description
            ? documentToPlainTextString(primaryComboFrameSlingCol.description)
            : primaryComboFrameSlingCol.frameSlingColName,
          image_src:
            Array.isArray(primaryComboFrameSlingCol.images) && primaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: primaryComboFrameSlingCol.images[0] }).src
              : '',
          image_alt:
            Array.isArray(primaryComboFrameSlingCol.images) && primaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: primaryComboFrameSlingCol.images[0] }).alt
              : '',
          metadata: {
            index: productIndexId,
            brand: product.vendor?.vendorName ?? '',
            category: product.category ?? '',
            category2: product.category2 ?? '',
            itemListName: previousRoute,
            contentful_id: primaryComboFrameSlingCol.CONTENTFUL_ID,
            query_id: algoliaQueryId ?? '',
          },
        })
      }

      if (secondaryComboFrameSlingCol) {
        if (!secondaryComboFrameSlingCol.sku) {
          // eslint-disable-next-line no-console
          console.error('Missing sku for secondary combo.')
          return toast.error('An unexpected error occurred. Please try again later')
        }

        childItems.push({
          sku: secondaryComboFrameSlingCol.sku,
          quantity: quantity,
          description: secondaryComboFrameSlingCol.frameSlingColName ?? 'Primary Combo',
          details: secondaryComboFrameSlingCol.description
            ? documentToPlainTextString(secondaryComboFrameSlingCol.description)
            : secondaryComboFrameSlingCol.frameSlingColName,
          image_src:
            Array.isArray(secondaryComboFrameSlingCol.images) && secondaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: secondaryComboFrameSlingCol.images[0] }).src
              : '',
          image_alt:
            Array.isArray(secondaryComboFrameSlingCol.images) && secondaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: secondaryComboFrameSlingCol.images[0] }).alt
              : '',
          metadata: {
            index: productIndexId,
            brand: product.vendor?.vendorName ?? '',
            category: product.category ?? '',
            category2: product.category2 ?? '',
            itemListName: previousRoute,
            contentful_id: secondaryComboFrameSlingCol.CONTENTFUL_ID,
            query_id: algoliaQueryId ?? '',
          },
        })
      }

      if (tertiaryComboFrameSlingCol) {
        if (!tertiaryComboFrameSlingCol.sku) {
          // eslint-disable-next-line no-console
          console.error('Missing sku for tertiary combo.')
          return toast.error('An unexpected error occurred. Please try again later')
        }

        childItems.push({
          sku: tertiaryComboFrameSlingCol.sku,
          quantity: quantity,
          description: tertiaryComboFrameSlingCol.frameSlingColName ?? 'Primary Combo',
          details: tertiaryComboFrameSlingCol.description
            ? documentToPlainTextString(tertiaryComboFrameSlingCol.description)
            : tertiaryComboFrameSlingCol.frameSlingColName,
          image_src:
            Array.isArray(tertiaryComboFrameSlingCol.images) && tertiaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: tertiaryComboFrameSlingCol.images[0] }).src
              : '',
          image_alt:
            Array.isArray(tertiaryComboFrameSlingCol.images) && tertiaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: tertiaryComboFrameSlingCol.images[0] }).alt
              : '',
          metadata: {
            index: productIndexId,
            brand: product.vendor?.vendorName ?? '',
            category: product.category ?? '',
            category2: product.category2 ?? '',
            itemListName: previousRoute,
            contentful_id: tertiaryComboFrameSlingCol.CONTENTFUL_ID,
            query_id: algoliaQueryId ?? '',
          },
        })
      }

      if (quaternaryComboFrameSlingCol) {
        if (!quaternaryComboFrameSlingCol.sku) {
          // eslint-disable-next-line no-console
          console.error('Missing sku for quaternary combo.')
          return toast.error('An unexpected error occurred. Please try again later')
        }

        childItems.push({
          sku: quaternaryComboFrameSlingCol.sku,
          quantity: quantity,
          description: quaternaryComboFrameSlingCol.frameSlingColName ?? 'Primary Combo',
          details: quaternaryComboFrameSlingCol.description
            ? documentToPlainTextString(quaternaryComboFrameSlingCol.description)
            : quaternaryComboFrameSlingCol.frameSlingColName,
          image_src:
            Array.isArray(quaternaryComboFrameSlingCol.images) && quaternaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: quaternaryComboFrameSlingCol.images[0] }).src
              : '',
          image_alt:
            Array.isArray(quaternaryComboFrameSlingCol.images) && quaternaryComboFrameSlingCol.images.length > 0
              ? normalizeAsset({ asset: quaternaryComboFrameSlingCol.images[0] }).alt
              : '',
          metadata: {
            index: productIndexId,
            brand: product.vendor?.vendorName ?? '',
            category: product.category ?? '',
            category2: product.category2 ?? '',
            itemListName: previousRoute,
            contentful_id: quaternaryComboFrameSlingCol.CONTENTFUL_ID,
            query_id: algoliaQueryId ?? '',
          },
        })
      }

      if (mainCushionFabric) {
        if (!mainCushionsUniqueSelection) {
          if (!mainCushionFabric.orderSku) {
            // eslint-disable-next-line no-console
            console.error('Missing orderSku for main cushion fabric.')
            return toast.error('An unexpected error occurred. Please try again later')
          }

          childItems.push({
            sku: mainCushionFabric.orderSku,
            price: mainCushionFabric.price,
            quantity: quantity * requiredQuantity,
            description: mainCushionsTitle ?? 'Cushion Fabric Grade',
            details: mainCushionFabric.description
              ? documentToPlainTextString(mainCushionFabric.description)
              : mainCushionFabric.fabricName,
            image_src:
              Array.isArray(mainCushionFabric.images) && mainCushionFabric.images.length > 0
                ? normalizeAsset({ asset: mainCushionFabric.images[0] }).src
                : '',
            image_alt:
              Array.isArray(mainCushionFabric.images) && mainCushionFabric.images.length > 0
                ? normalizeAsset({ asset: mainCushionFabric.images[0] }).alt
                : '',
            metadata: {
              index: productIndexId,
              brand: product.vendor?.vendorName ?? '',
              category: product.category ?? '',
              category2: product.category2 ?? '',
              itemListName: previousRoute,
              contentful_id: mainCushionFabric.CONTENTFUL_ID,
              query_id: algoliaQueryId ?? '',
            },
          })
          // for main and optional cushions we are adding the fabric to child items as well. The fabric sku
          // is necessary for the ERP system to know what fabric the customer wants. The orderSku above is
          // just the fabric grade and not the actual fabric. While the fabric grade impacts the price, the fabric
          // itself doesn't. That's why we set price to 0 and quantity to 1. A missing faric sku non-fatal and we
          // are not erroring out due to that.
          if (mainCushionFabric.sku) {
            childItems.push({
              sku: mainCushionFabric.sku,
              price: 0,
              quantity: 1,
              description: 'Cushion Fabric',
              details: mainCushionFabric.description
                ? documentToPlainTextString(mainCushionFabric.description)
                : mainCushionFabric.fabricName,
            })
          } else {
            // eslint-disable-next-line no-console
            console.warn('Missing fabric sku for main cushion.')
          }
        } else if (Array.isArray(uniqueMainCushionFabrics) && uniqueMainCushionFabrics.length > 0) {
          uniqueMainCushionFabrics.forEach((uniqueMainCushion) => {
            if (!uniqueMainCushion.orderSku) {
              // eslint-disable-next-line no-console
              console.error('Missing orderSku for main cushion fabric.')
              return toast.error('An unexpected error occurred. Please try again later')
            }

            childItems.push({
              sku: uniqueMainCushion.orderSku,
              price: mainCushionFabric.price,
              quantity: quantity * requiredQuantity,
              description: mainCushionsTitle ?? 'Cushion Fabric',
              details: uniqueMainCushion.description
                ? documentToPlainTextString(uniqueMainCushion.description)
                : mainCushionFabric.fabricName,
              image_src:
                Array.isArray(uniqueMainCushion.images) && uniqueMainCushion.images.length > 0
                  ? normalizeAsset({ asset: uniqueMainCushion.images[0] }).src
                  : '',
              image_alt:
                Array.isArray(uniqueMainCushion.images) && uniqueMainCushion.images.length > 0
                  ? normalizeAsset({ asset: uniqueMainCushion.images[0] }).alt
                  : '',
              metadata: {
                index: productIndexId,
                brand: product.vendor?.vendorName ?? '',
                category: product.category ?? '',
                category2: product.category2 ?? '',
                itemListName: previousRoute,
                contentful_id: uniqueMainCushion.CONTENTFUL_ID,
                query_id: algoliaQueryId ?? '',
              },
            })

            if (mainCushionFabric.sku) {
              childItems.push({
                sku: mainCushionFabric.sku,
                price: 0,
                quantity: 1,
                description: 'Cushion Fabric',
                details: mainCushionFabric.description
                  ? documentToPlainTextString(mainCushionFabric.description)
                  : mainCushionFabric.fabricName,
              })
            } else {
              // eslint-disable-next-line no-console
              console.warn('Missing fabric sku for main cushion.')
            }
          })
        }
      }

      const optionalItems: FurnitureCartItem[] = []

      if (Array.isArray(optionalCushionGroupFabrics) && optionalCushionGroupFabrics.length > 0) {
        optionalCushionGroupFabrics.forEach((optionalCushionGroupFabric) => {
          if (!optionalCushionGroupFabric.orderSku) {
            // eslint-disable-next-line no-console
            console.error('Missing orderSku for optional cushion fabric.')
            return toast.error('An unexpected error occurred. Please try again later')
          }

          optionalItems.push({
            sku: optionalCushionGroupFabric.orderSku,
            quantity:
              optionalCushionGroupFabric.hasQuantity && optionalCushionGroupFabric.quantity
                ? optionalCushionGroupFabric.quantity
                : quantity,
            description: optionalCushionGroupFabric.cushionGroupName ?? 'Optional Cushion Fabric',
            details: optionalCushionGroupFabric.description
              ? documentToPlainTextString(optionalCushionGroupFabric.description)
              : optionalCushionGroupFabric.fabricName,
            image_src:
              Array.isArray(optionalCushionGroupFabric.images) && optionalCushionGroupFabric.images.length > 0
                ? normalizeAsset({ asset: optionalCushionGroupFabric.images[0] }).src
                : '',
            image_alt:
              Array.isArray(optionalCushionGroupFabric.images) && optionalCushionGroupFabric.images.length > 0
                ? normalizeAsset({ asset: optionalCushionGroupFabric.images[0] }).alt
                : '',
            metadata: {
              index: productIndexId,
              brand: product.vendor?.vendorName ?? '',
              category: product.category ?? '',
              category2: product.category2 ?? '',
              itemListName: previousRoute,
              contentful_id: optionalCushionGroupFabric.CONTENTFUL_ID,
              query_id: algoliaQueryId ?? '',
            },
          })

          if (optionalCushionGroupFabric.sku) {
            childItems.push({
              sku: optionalCushionGroupFabric.sku,
              price: 0,
              quantity: 1,
              description: 'Cushion Fabric',
              details: optionalCushionGroupFabric.description
                ? documentToPlainTextString(optionalCushionGroupFabric.description)
                : optionalCushionGroupFabric.fabricName,
            })
          } else {
            // eslint-disable-next-line no-console
            console.warn('Missing fabric sku for optional cushion.')
          }
        })
      }

      if (Array.isArray(addonsFabricOrFrameSlingCol) && addonsFabricOrFrameSlingCol.length > 0) {
        addonsFabricOrFrameSlingCol.forEach((addonFabricOrFrameSlingCol) => {
          if (!addonFabricOrFrameSlingCol.orderSku) {
            // eslint-disable-next-line no-console
            console.error('Missing orderSku for addon fabric.')
            return toast.error('An unexpected error occurred. Please try again later')
          }

          optionalItems.push({
            sku: addonFabricOrFrameSlingCol.orderSku,
            quantity: addonFabricOrFrameSlingCol.quantity ?? 1,
            description: addonFabricOrFrameSlingCol.addonName,
            details: addonFabricOrFrameSlingCol.description
              ? documentToPlainTextString(addonFabricOrFrameSlingCol.description)
              : '',
            image_src:
              Array.isArray(addonFabricOrFrameSlingCol.images) && addonFabricOrFrameSlingCol.images.length > 0
                ? normalizeAsset({ asset: addonFabricOrFrameSlingCol.images[0] }).src
                : '',
            image_alt:
              Array.isArray(addonFabricOrFrameSlingCol.images) && addonFabricOrFrameSlingCol.images.length > 0
                ? normalizeAsset({ asset: addonFabricOrFrameSlingCol.images[0] }).alt
                : '',
            metadata: {
              index: productIndexId,
              brand: product.vendor?.vendorName ?? '',
              category: product.category ?? '',
              category2: product.category2 ?? '',
              itemListName: previousRoute,
              contentful_id: addonFabricOrFrameSlingCol.CONTENTFUL_ID,
              query_id: algoliaQueryId ?? '',
            },
          })
        })
      }

      const productDetailInfo = []
      const frameDescriptions = getFramesDescription(product, state)

      if (fabric) productDetailInfo.push(`Fabric Grade: ${fabric ? fabric.fabricName : ''}`)
      if (frameDescriptions) productDetailInfo.push(frameDescriptions)
      if (mainCushionFabric) {
        productDetailInfo.push(
          `${mainCushionsTitle ?? 'Cushion Fabric'}: ${mainCushionFabric ? mainCushionFabric.fabricName : ''}`,
        )
      }

      if (addonsGroupFabricOrFrameSlingCol && Object.keys(addonsGroupFabricOrFrameSlingCol).length > 0) {
        const joinAddons = Object.values(addonsGroupFabricOrFrameSlingCol).reduce((acc, val) => acc.concat(val), [])

        joinAddons.forEach(({ orderSku, quantity, addonName, images, CONTENTFUL_ID }) => {
          if (!orderSku) {
            // eslint-disable-next-line no-console
            console.error('Missing orderSku for addon group fabric.')
            return toast.error('An unexpected error occurred. Please try again later')
          }

          const addonGroup = addonGroups?.find((group) =>
            group.addons?.some((addon) => addon.CONTENTFUL_ID === CONTENTFUL_ID),
          )

          if (addonsGroupFabricOrFrameSlingCol && addonGroup?.required) {
            productDetailInfo.push(
              `${addonGroup.addonGroupName}: ${addonsGroupFabricOrFrameSlingCol[addonGroup.CONTENTFUL_ID]?.map(
                (addon) => addon.name,
              )}`,
            )
          }

          const item = {
            sku: orderSku,
            quantity: quantity ?? 1,
            description: addonName,
            details: '',
            image_src: Array.isArray(images) && images.length > 0 ? normalizeAsset({ asset: images[0] }).src : '',
            image_alt: Array.isArray(images) && images.length > 0 ? normalizeAsset({ asset: images[0] }).alt : '',
            metadata: {
              contentful_id: product.CONTENTFUL_ID,
              query_id: algoliaQueryId ?? '',
            },
          }

          if (addonGroup?.required) {
            childItems.push(item)
          } else {
            optionalItems.push(item)
          }
        })
      }

      const mainItem: FurnitureCartItem = {
        sku: orderSku,
        quantity,
        description: productName,
        details: productDetailInfo.join('; '),
        image_src: Array.isArray(images) && images.length > 0 ? normalizeAsset({ asset: images[0] }).src : '',
        image_alt: Array.isArray(images) && images.length > 0 ? normalizeAsset({ asset: images[0] }).alt : '',
        metadata: {
          link: window.location.href,
          index: productIndexId,
          brand: product.vendor?.vendorName ?? '',
          category: product.category ?? '',
          category2: product.category2 ?? '',
          itemListName: previousRoute,
          contentful_id: product.CONTENTFUL_ID,
          query_id: algoliaQueryId ?? '',
        },
      }

      addFurnitureProductToCart({ ...mainItem, childItems, optionalItems })
        .then((res) => {
          const titles = {
            fabricTitle: fabricGradesTitle ?? FABRIC_GRADES_DEFAULT_TITLE,
            mainCushionsTitle: mainCushionsTitle ?? MAIN_CUSHIONS_DEFAULT_TITLE,
            addonsTitle: addonsTitle ?? ADDONS_DEFAULT_TITLE,
          }
          const infoProduct = getModalOrderInfo(formatPrice(totalPrice ?? basePrice), quantity, product, state, titles)
          sendCartAnalyticsEvent(res, CartEventTypes.ADD_TO_CART)

          aa('convertedObjectIDsAfterSearch', {
            index: environment.algolia.recordsIndex,
            eventName: 'Product Added To Cart',
            objectIDs: [mainItem.sku ?? ''],
            queryID: algoliaQueryId ?? '',
          })
          handlerClick(infoProduct)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)

          toast.error('Something went wrong. Please try again later.')
        })
        .finally(() => {
          setIsSubmitting(false)
          removeStoredProductIndexID(null)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      product,
      basePriceNotFound,
      mainCushionPriceNotFound,
      optionalCushionGroupPriceNotFound,
      addonPriceNotFound,
      addonsGroupPriceNotFound,
      router,
      orderSku,
      fabric,
      primaryComboFrameSlingCol,
      secondaryComboFrameSlingCol,
      tertiaryComboFrameSlingCol,
      quaternaryComboFrameSlingCol,
      mainCushionFabric,
      optionalCushionGroupFabrics,
      addonsFabricOrFrameSlingCol,
      state,
      addonsGroupFabricOrFrameSlingCol,
      quantity,
      productName,
      images,
      productIndexId,
      previousRoute,
      addFurnitureProductToCart,
      mainCushionsUniqueSelection,
      uniqueMainCushionFabrics,
      requiredQuantity,
      mainCushionsTitle,
      addonGroups,
      fabricGradesTitle,
      addonsTitle,
      totalPrice,
      basePrice,
      handlerClick,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: FurnitureProductActionTypes.SET_QUANTITY, quantity: Number(value) })
      dispatch({ type: FurnitureProductActionTypes.SET_TOTAL_PRICE })
    },
    [dispatch],
  )

  const handleIncreaseQuantity = useCallback(() => {
    dispatch({ type: FurnitureProductActionTypes.SET_QUANTITY, quantity: quantity + 1 })
    dispatch({ type: FurnitureProductActionTypes.SET_TOTAL_PRICE })
  }, [dispatch, quantity])

  const handleDecreaseQuantity = useCallback(() => {
    dispatch({ type: FurnitureProductActionTypes.SET_QUANTITY, quantity: quantity - 1 })
    dispatch({ type: FurnitureProductActionTypes.SET_TOTAL_PRICE })
  }, [dispatch, quantity])

  const handleClearOptions = useCallback(() => {
    // This action is dispatched to clear the options of the furniture product.
    dispatch({ type: FurnitureProductActionTypes.RESET_ALL_OPTIONS })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, asPath])

  useEffect(() => {
    if (product && !isLoadingPrices) {
      const timer = setTimeout(() => {
        dispatch({
          type: FurnitureProductActionTypes.SET_INITIAL_STATE_PRODUCT,
          additionalState: { productsDisabled: false },
        })
      }, 1000)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isLoadingPrices])

  useEffect(() => {
    dispatch({
      type: FurnitureProductActionTypes.SET_DISABLED_STATE,
      payload: { clearSelectionsDisabled: true, productsDisabled: true },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  return (
    <>
      <form onSubmit={handleSubmit} className="relative text-secondary flex flex-col gap-4 xl:basis-5/12">
        {/* Header Desktop */}
        {!isMobile && (
          <ProductDescription
            product={product}
            cushionMinPrice={cushionMinPrice}
            startingPrice={startingPrice}
            className="sr-only xl:not-sr-only"
          />
        )}

        {/* Option header */}
        {(hasFabricGrades ||
          hasCombos ||
          hasMainCushions ||
          hasOptionalCushionsGroup ||
          hasAddons ||
          hasAddonsGroup) && (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col gap-1">
              <p className="font-medium">Select your options</p>
              <span className="text-sm flex flex-row gap-1">
                Required <span className="text-error text-xl leading-6 font-medium">*</span>
              </span>
            </div>

            <AtButton
              label="Clear Selection"
              variant={AtButtonVariant.TERTIARY}
              size={AtButtonSize.COMPACT}
              onClick={handleClearOptions}
              state={!clearSelectionsDisabled ? AtButtonState.ENABLED : AtButtonState.LOADING}
            />
          </div>
        )}

        {/* Collapses */}
        {hasCombos && (
          <Combos
            useGroupedOptions={useGroupedOptions}
            initialToggleCollapse={true}
            productPrices={productPrices}
            collapsePosition={collapsePosition}
            combos={combos}
          />
        )}

        {hasPresetFabrics && hasFabricGrades && (
          <PresetFabrics
            initialToggleCollapse={!hasCombos}
            fabricGradesTitle={fabricGradesTitle}
            collapsePosition={collapsePosition}
            fabricGrades={fabricGrades}
          />
        )}

        {!hasPresetFabrics && hasFabricGrades && (
          <FabricGrades
            initialToggleCollapse={!hasCombos}
            fabricGradesTitle={fabricGradesTitle}
            collapsePosition={collapsePosition}
            fabricGrades={fabricGrades}
            productPrices={productPrices}
          />
        )}

        {hasMainCushions && (
          <MainCushions
            initialToggleCollapse={!hasFabricGrades && !hasCombos && !hasFabricGrades}
            mainCushionsUniqueSelection={mainCushionsUniqueSelection}
            mainCushionsTitle={mainCushionsTitle}
            collapsePosition={collapsePosition}
            mainCushions={mainCushions}
            requiredQuantity={requiredQuantity}
          />
        )}

        {hasOptionalCushionsGroup &&
          optionalCushionsGroup.map((optionalCushionGroup, index) => (
            <OptionalCushionsGroup
              key={`optional-cushion-group-${optionalCushionGroup.CONTENTFUL_ID}-${index}`}
              initialToggleCollapse={!hasFabricGrades && !hasCombos && !hasFabricGrades && !hasMainCushions}
              collapsePosition={collapsePosition}
              optionalCushionGroup={optionalCushionGroup}
              position={index}
            />
          ))}

        {hasAddons && (
          <Addons
            addonsTitle={addonsTitle}
            initialToggleCollapse={
              !hasFabricGrades && !hasCombos && !hasFabricGrades && !hasMainCushions && !hasOptionalCushionsGroup
            }
            collapsePosition={collapsePosition}
            addons={addons}
          />
        )}

        {hasAddonsGroup &&
          addonGroups.map((addonGroup, index) => (
            <AddonsGroup
              key={`addons-group-${addonGroup.CONTENTFUL_ID}-${index}`}
              initialToggleCollapse={
                !hasFabricGrades &&
                !hasCombos &&
                !hasFabricGrades &&
                !hasMainCushions &&
                !hasOptionalCushionsGroup &&
                !hasAddons
              }
              collapsePosition={collapsePosition}
              addonGroup={addonGroup}
              position={index}
            />
          ))}

        {/* Quantity */}
        {!product.callForQuote &&
          !basePriceNotFound &&
          !mainCushionPriceNotFound &&
          !optionalCushionGroupPriceNotFound &&
          !addonPriceNotFound &&
          !addonsGroupPriceNotFound && (
            <div className="flex flex-row justify-between items-center mt-4">
              <Quantity
                min={1}
                max={50}
                value={quantity}
                helperText={product.quantityHelperText}
                handleChange={handleChange}
                increase={handleIncreaseQuantity}
                decrease={handleDecreaseQuantity}
              />
              <span className="font-medium text-base text-dark">{formatPrice(totalPrice ?? basePrice)}</span>
            </div>
          )}

        {/* Order Button */}
        <AtButton
          id="addToCartButton"
          type="submit"
          disabled={
            (!(
              isFabricGradesDisabled() &&
              isCombosDisabled() &&
              isMainCushionsDisabled() &&
              isAddonsGroupDisabled() &&
              isPricesDisabled
            ) ||
              isSubmitting ||
              isLoadingPrices) &&
            !product.callForQuote
          }
          label={
            isSubmitting || isLoadingPrices
              ? 'Loading'
              : product.callForQuote ||
                basePriceNotFound ||
                mainCushionPriceNotFound ||
                optionalCushionGroupPriceNotFound ||
                addonPriceNotFound ||
                addonsGroupPriceNotFound
              ? 'Request Quote'
              : 'Add to Cart'
          }
          state={
            (!(
              isFabricGradesDisabled() &&
              isCombosDisabled() &&
              isMainCushionsDisabled() &&
              isAddonsGroupDisabled() &&
              isPricesDisabled
            ) ||
              isSubmitting ||
              isLoadingPrices) &&
            !product.callForQuote
              ? AtButtonState.DISABLED
              : AtButtonState.ENABLED
          }
          icon={isSubmitting || isLoadingPrices ? { type: 'spinner' } : undefined}
          variant={AtButtonVariant.SECONDARY}
        />

        <ShoppingInfo />

        {/* Shopping info text*/}
        {Array.isArray(product.additionalInformation) &&
          product.additionalInformation.length > 0 &&
          product.additionalInformation.map((infoItem, index) => (
            <AtInfoItem
              key={`add-info-${infoItem.CONTENTFUL_ID}-${index}`}
              {...normalizeInfoItem(infoItem)}
              variant={AtInfoItemVariant.SHIPPING}
            />
          ))}
      </form>

      {/*Modal order*/}
      <OrModal
        productContent={modalInfo?.productContent}
        isOpen={isModalOpen && !isSubmitting}
        icon="circle-check"
        title="Added to Shopping Cart"
        leftButton={{
          label: 'Continue Shopping',
          onClick: () => {
            setIsModalOpen(false)
          },
          variant: AtButtonVariant.TERTIARY,
        }}
        rightButton={{
          label: 'View Cart',
          onClick: () => {
            setCartPageLoading(true)
            router.push('/cart')
          },
          state: isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED,
          icon: isCartPageLoading ? { type: 'spinner' } : undefined,
          disabled: isCartPageLoading,
        }}
        handleCloseModal={() => setIsModalOpen(false)}
      />
    </>
  )
})

function getFramesDescription(product: ContentfulObjFurnitureProduct, state: FurnitureProductState) {
  const arrayFrameDescriptions = []
  product &&
    product.combos &&
    state.primaryComboFrameSlingCol &&
    arrayFrameDescriptions.push(`${product.combos[0].comboName}: ${state.primaryComboFrameSlingCol?.frameSlingColName}`)

  product &&
    product.combos &&
    state.secondaryComboFrameSlingCol &&
    arrayFrameDescriptions.push(
      `${product.combos[1].comboName}: ${state.secondaryComboFrameSlingCol?.frameSlingColName}`,
    )

  product &&
    product.combos &&
    state.tertiaryComboFrameSlingCol &&
    arrayFrameDescriptions.push(
      `${product.combos[2].comboName}: ${state.tertiaryComboFrameSlingCol?.frameSlingColName}`,
    )

  product &&
    product.combos &&
    state.quaternaryComboFrameSlingCol &&
    arrayFrameDescriptions.push(
      `${product.combos[3].comboName}: ${state.quaternaryComboFrameSlingCol?.frameSlingColName}`,
    )

  return arrayFrameDescriptions.join('; ')
}
