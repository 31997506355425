import { memo, useCallback } from 'react'

import { ComboColorOptions, MlProductOptionsCollapse, RadioOptions } from '@components/product-detail-options'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ComboLength } from './product-detail-collapses'
import { ComboVariant, ContentfulObjFurnitureCombo, ErrorFurniturePrices, FurniturePrices } from 'types'

interface CombosProps {
  initialToggleCollapse: boolean
  productPrices: FurniturePrices | ErrorFurniturePrices
  collapsePosition: string[]
  combos: ContentfulObjFurnitureCombo[]
  useGroupedOptions?: boolean
}

export const Combos = memo(
  ({ initialToggleCollapse, productPrices, collapsePosition, combos, useGroupedOptions }: CombosProps) => {
    const { state } = useFurnitureProduct()
    const {
      primaryComboFrameSlingCol,
      secondaryComboFrameSlingCol,
      tertiaryComboFrameSlingCol,
      quaternaryComboFrameSlingCol,
    } = state

    const handleComboSelected = useCallback(
      (position: number) => {
        if (position === ComboLength.PRIMARY) {
          return primaryComboFrameSlingCol?.CONTENTFUL_ID ?? ''
        }
        if (position === ComboLength.SECONDARY) {
          return secondaryComboFrameSlingCol?.CONTENTFUL_ID ?? ''
        }
        if (position === ComboLength.TERTIARY) {
          return tertiaryComboFrameSlingCol?.CONTENTFUL_ID ?? ''
        }
        if (position === ComboLength.QUATERNARY) {
          return quaternaryComboFrameSlingCol?.CONTENTFUL_ID ?? ''
        }
        return ''
      },
      [
        primaryComboFrameSlingCol?.CONTENTFUL_ID,
        secondaryComboFrameSlingCol?.CONTENTFUL_ID,
        tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
        quaternaryComboFrameSlingCol?.CONTENTFUL_ID,
      ],
    )

    return (
      <>
        {combos
          .map((combo, index) => {
            if (Array.isArray(combo.items) && combo.items.length > 0) {
              return useGroupedOptions
                ? combo
                : {
                    ...combo,
                    items: combo.items.map((comboItem) => {
                      const position = index + 1
                      if (position === ComboLength.SECONDARY && primaryComboFrameSlingCol) {
                        return {
                          ...comboItem,
                          parentPrimaryId: (productPrices as FurniturePrices).products.find(
                            ({ option1, option2 }) =>
                              option2 === comboItem.CONTENTFUL_ID &&
                              primaryComboFrameSlingCol.CONTENTFUL_ID === option1,
                          )?.option1,
                        }
                      }

                      if (
                        position === ComboLength.TERTIARY &&
                        primaryComboFrameSlingCol &&
                        secondaryComboFrameSlingCol
                      ) {
                        return {
                          ...comboItem,
                          parentSecondaryId: (productPrices as FurniturePrices).products.find(
                            ({ option1, option2, option3 }) =>
                              option3 === comboItem.CONTENTFUL_ID &&
                              primaryComboFrameSlingCol.CONTENTFUL_ID === option1 &&
                              secondaryComboFrameSlingCol.CONTENTFUL_ID === option2,
                          )?.option2,
                        }
                      }

                      if (
                        position === ComboLength.QUATERNARY &&
                        primaryComboFrameSlingCol &&
                        secondaryComboFrameSlingCol &&
                        tertiaryComboFrameSlingCol
                      ) {
                        return {
                          ...comboItem,
                          parentTertiaryId: (productPrices as FurniturePrices).products.find(
                            ({ option1, option2, option3, option4 }) =>
                              option4 === comboItem.CONTENTFUL_ID &&
                              primaryComboFrameSlingCol.CONTENTFUL_ID === option1 &&
                              secondaryComboFrameSlingCol.CONTENTFUL_ID === option2 &&
                              tertiaryComboFrameSlingCol.CONTENTFUL_ID === option3,
                          )?.option3,
                        }
                      }

                      return comboItem
                    }),
                  }
            }

            return combo
          })
          .map((combo, index) => (
            <MlProductOptionsCollapse
              key={`combo-${index}-${combo.CONTENTFUL_ID}`}
              initialToggleCollapse={index === 0 && initialToggleCollapse}
              index={collapsePosition.findIndex((option) => option === `combo${index + 1}`) + 1}
              isRequired={true}
              name={combo.comboName}
              optionSelected={
                Array.isArray(combo.items) && combo.items.length > 0
                  ? combo.items.find((item) => item.CONTENTFUL_ID === handleComboSelected(index + 1))?.frameSlingColName
                  : ''
              }
            >
              {Array.isArray(combo.items) && combo.items.length > 0 ? (
                <>
                  {combo.variant === ComboVariant.THUMBNAIL ? (
                    <ComboColorOptions
                      hierarchy={index + 1}
                      options={combo.items}
                      useGroupedOptions={useGroupedOptions}
                    />
                  ) : (
                    <RadioOptions hierarchy={index + 1} options={combo.items} useGroupedOptions={useGroupedOptions} />
                  )}
                </>
              ) : (
                <p className="p-3">No Items.</p>
              )}
            </MlProductOptionsCollapse>
          ))}
      </>
    )
  },
)
