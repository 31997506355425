import { environment } from '@config/environment'
import { AtLink, AtLinkVariant } from '@curran-catalog/curran-atomic-library'
import { SiteDomain } from 'types'

export const ShoppingInfo = () => {
  return (
    <div className="text-sm">
      <p>
        Shopping for a client? Create a trade{' '}
        <AtLink label="account" variant={AtLinkVariant.DEFAULT} actionUrl="/register" className="w-fit" /> or{' '}
        <AtLink label="log in" variant={AtLinkVariant.DEFAULT} actionUrl="/login" className="w-fit" /> to see your
        discount
        {environment.siteDomain === SiteDomain.FURNITURE ? '.' : ' and order free samples.'}{' '}
      </p>
    </div>
  )
}
