import { useCallback, useEffect } from 'react'

import { FurnitureProductActionTypes, ObjAddonState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { CheckboxOptions, MlProductOptionsCollapse } from '@components/product-detail-options'
import { ContentfulObjAddon } from 'types'

export const ADDONS_DEFAULT_TITLE = 'Addons'

interface AddonsProps {
  addonsTitle?: string
  initialToggleCollapse: boolean
  collapsePosition: string[]
  addons: ContentfulObjAddon[]
}

export const Addons = ({ initialToggleCollapse, addonsTitle, addons, collapsePosition }: AddonsProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const { addonsFabricOrFrameSlingCol } = state

  const handleCheckboxOption = useCallback(
    (updatedState: ObjAddonState[]) => {
      dispatch({
        type: FurnitureProductActionTypes.SET_ADDONS,
        addonsFabricOrFrameSlingCol: updatedState,
      })

      const hasAddonPriceNotFound = updatedState.some(({ price, orderSku }) => !price && !orderSku)

      dispatch({
        type: FurnitureProductActionTypes.SET_ADDON_PRICE_NOT_FOUND,
        addonPriceNotFound: hasAddonPriceNotFound,
      })

      dispatch({
        type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
      })
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch({
      type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addonsFabricOrFrameSlingCol])

  return (
    <MlProductOptionsCollapse
      index={collapsePosition.findIndex((option) => option === 'addons') + 1}
      isRequired={false}
      initialToggleCollapse={initialToggleCollapse}
      name={addonsTitle ?? ADDONS_DEFAULT_TITLE}
      isMultiValue
      optionSelected={
        addonsFabricOrFrameSlingCol
          ? addons.find((addon) =>
              addonsFabricOrFrameSlingCol.find((selectedAddon) => selectedAddon.CONTENTFUL_ID === addon.CONTENTFUL_ID),
            )?.addonName
          : undefined
      }
    >
      <CheckboxOptions options={addons} handleContextAction={handleCheckboxOption} />
    </MlProductOptionsCollapse>
  )
}
