import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes, ObjAddonState } from 'context'
import { Quantity, QuantityVariant } from '@components/product-detail-options'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { formatPrice } from '@utils/price'
import { normalizeAsset } from '@utils/normalize'
import { ContentfulObjAddonGroup, FurniturePrices } from 'types'

interface CheckboxOptionProps {
  index: number
  isChecked?: boolean
  option: ObjAddonState
  hasQuantity: boolean
  showImage: boolean
  handleInputChange: (
    position: number,
    price: number,
    quantity: number,
    option: ObjAddonState,
    orderSku?: string,
  ) => void
  handleButtonQuantityChange: (position: number, price: number, quantity: number) => void
}

const CheckboxOption = memo(
  ({
    index,
    isChecked = false,
    hasQuantity,
    showImage,
    option,
    handleInputChange,
    handleButtonQuantityChange,
  }: CheckboxOptionProps) => {
    const { CONTENTFUL_ID, addonPrices, sku, addonName, uniqueID, images } = option

    const { state, dispatch } = useFurnitureProduct()
    const { addonsFabricOrFrameSlingCol, addonsGroupFabricOrFrameSlingCol, productsDisabled } = state

    const [quantity, setQuantity] = useState<number>(1)
    const [isQuantityUpdated, setQuantityUpdated] = useState(false)

    const getAddon = useCallback(() => {
      if (
        addonPrices &&
        Array.isArray((addonPrices as FurniturePrices).products) &&
        (addonPrices as FurniturePrices).products.length > 0
      ) {
        return (addonPrices as FurniturePrices).products[0]
      }
      return undefined
    }, [addonPrices])

    // Reset the quantity when clear-options btn is clicked
    useEffect(() => {
      if (addonsFabricOrFrameSlingCol === undefined) {
        setQuantity(1)
      }
    }, [addonsFabricOrFrameSlingCol, productsDisabled])

    useEffect(() => {
      if (addonsGroupFabricOrFrameSlingCol === undefined) {
        setQuantity(1)
      }
    }, [addonsGroupFabricOrFrameSlingCol, productsDisabled])

    useEffect(() => {
      if (isQuantityUpdated) {
        handleButtonQuantityChange(index, (getAddon()?.price ?? 0) * quantity, quantity)

        setQuantityUpdated(false)
      }
    }, [isQuantityUpdated, handleButtonQuantityChange, index, quantity, addonPrices, getAddon, productsDisabled])

    const handleQuantityChange = useCallback((value = 1) => {
      setQuantity((prevState) => prevState + value)
      setQuantityUpdated(true)
    }, [])

    return (
      <li
        className={`flex flex-col gap-x-2 p-2 ${uniqueID ? 'border' : ''} ${
          isChecked ? 'border-secondary' : ' border-outlined-gray'
        }`}
        onClick={() =>
          dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
        }
      >
        <MlFormField
          disabled={!!productsDisabled}
          fieldId={uniqueID ? `${uniqueID}-${CONTENTFUL_ID}` : CONTENTFUL_ID}
          label={`${addonName} ${getAddon()?.price ? `(+${formatPrice((getAddon()?.price ?? 0) * quantity)})` : ''} `}
          name={uniqueID ? `${uniqueID}-${CONTENTFUL_ID}` : CONTENTFUL_ID}
          type="checkbox"
          value={sku}
          checked={isChecked}
          onChange={() => {
            handleInputChange(index, (getAddon()?.price ?? 0) * quantity, quantity, option, getAddon()?.sku)
          }}
          image={
            Array.isArray(images) && images.length > 0 && showImage ? normalizeAsset({ asset: images[0] }) : undefined
          }
        />
        {isChecked && hasQuantity && (
          <div className={Array.isArray(images) && images.length > 0 && showImage ? 'ml-24' : 'ml-6'}>
            <Quantity
              variant={QuantityVariant.SMALL}
              min={1}
              max={99}
              value={quantity}
              handleChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                setQuantity(Number(value))
                setQuantityUpdated(true)
              }}
              increase={() => handleQuantityChange(1)}
              decrease={() => handleQuantityChange(-1)}
            />
          </div>
        )}
      </li>
    )
  },
)

interface CheckboxOptionsProps {
  addonGroupId?: ContentfulObjAddonGroup['CONTENTFUL_ID']
  options: ObjAddonState[]
  showImage?: boolean
  hasQuantity?: boolean
  handleContextAction: (updatedState: ObjAddonState[]) => void
}

export const CheckboxOptions = ({
  hasQuantity = true,
  addonGroupId,
  options,
  showImage = false,
  handleContextAction,
}: CheckboxOptionsProps) => {
  const { state } = useFurnitureProduct()
  const { addonsFabricOrFrameSlingCol, addonsGroupFabricOrFrameSlingCol, productsDisabled } = state

  const [checkedState, setCheckedState] = useState<ObjAddonState[]>([])

  useEffect(() => {
    if (addonsFabricOrFrameSlingCol === undefined) {
      setCheckedState(new Array(options.length).fill({ isChecked: false, price: 0, quantity: 1 }))
    }
  }, [addonsFabricOrFrameSlingCol, options.length, productsDisabled])

  useEffect(() => {
    if (addonsGroupFabricOrFrameSlingCol === undefined) {
      setCheckedState(new Array(options.length).fill({ isChecked: false, price: 0, quantity: 1 }))
    }
  }, [addonsGroupFabricOrFrameSlingCol, options.length, productsDisabled])

  const handleInputChange = useCallback(
    (position: number, price: number, quantity: number, option: ObjAddonState, orderSku?: string) => {
      const updatedState = checkedState.map((checkState, index) =>
        index === position
          ? {
              ...option,
              isChecked: !checkState.isChecked,
              price,
              quantity,
              orderSku,
            }
          : checkState,
      )

      setCheckedState(updatedState)

      handleContextAction(updatedState.filter((filteredState) => filteredState.isChecked))
    },

    [checkedState, handleContextAction],
  )

  const handleButtonQuantityChange = useCallback(
    (position: number, price: number, quantity: number) => {
      const updatedState = checkedState.map((checkState, index) =>
        index === position ? { ...checkState, price, quantity } : checkState,
      )

      setCheckedState(updatedState)

      handleContextAction(updatedState.filter((filteredState) => filteredState.isChecked))
    },

    [checkedState, handleContextAction],
  )

  return (
    <ul className="flex flex-col gap-4 flex-wrap px-4 my-5 bg-white">
      {options.map((option, index) => {
        const { CONTENTFUL_ID } = option

        return (
          <CheckboxOption
            key={`cover-list-${index}-${addonGroupId ? `${addonGroupId}-${CONTENTFUL_ID}` : CONTENTFUL_ID}`}
            index={index}
            option={addonGroupId ? { ...option, uniqueID: `${addonGroupId}-${CONTENTFUL_ID}` } : option}
            isChecked={checkedState[index]?.isChecked ?? false}
            hasQuantity={hasQuantity}
            showImage={showImage}
            handleInputChange={handleInputChange}
            handleButtonQuantityChange={handleButtonQuantityChange}
          />
        )
      })}
    </ul>
  )
}
