import { ReactNode, useCallback, useState } from 'react'
import { AtIcon, FocusElement, focusElementClasses } from '@curran-catalog/curran-atomic-library'

interface MlProductOptionsCollapseProps {
  initialToggleCollapse?: boolean
  children: ReactNode
  index: number
  isRequired: boolean
  isMultiValue?: boolean
  name: string
  isEmpty?: boolean
  optionSelected?: string
}

export const MlProductOptionsCollapse = ({
  initialToggleCollapse = false,
  children,
  index,
  isRequired,
  isMultiValue = false,
  name,
  optionSelected,
  isEmpty = true,
}: MlProductOptionsCollapseProps) => {
  const [toggleOptionsCollapse, setToggleOptionsCollapse] = useState<boolean>(initialToggleCollapse)

  const onToggleFabricGrade = useCallback(() => {
    setToggleOptionsCollapse(!toggleOptionsCollapse)
  }, [toggleOptionsCollapse])

  return (
    <div>
      <button
        type="button"
        className={`w-full min-h-fit ${focusElementClasses[FocusElement.FOCUS_VISIBLE]}`}
        aria-expanded={toggleOptionsCollapse ? 'true' : 'false'}
        onClick={() => {
          if (typeof onToggleFabricGrade === 'function') {
            onToggleFabricGrade()
          }
        }}
      >
        <div className="bg-subdued flex items-center justify-between py-4 px-3">
          <div className="flex flex-row gap-x-3 items-center text-secondary">
            <span
              className={`border flex flex-row items-center justify-center w-9 h-9 flex-grow-0 flex-shrink-0 basis-9 ${
                optionSelected || !isEmpty ? 'bg-secondary text-white' : ''
              }`}
            >
              {index}
            </span>
            <div>
              <p
                className={`uppercase text-left ${optionSelected && !isMultiValue ? 'text-sm' : 'text-lg font-medium'}`}
              >
                {name}
                {isRequired && <span className="text-error text-xl leading-6 font-medium ml-1">*</span>}
              </p>
              <div className="flex flex-row">
                {!!optionSelected && !isMultiValue && <p className="text-left text-lg font-medium">{optionSelected}</p>}
              </div>
            </div>
          </div>
          <div className="basis-6">
            <AtIcon type={toggleOptionsCollapse ? 'minus' : 'plus'} color="secondary" />
          </div>
        </div>
      </button>

      <div className={toggleOptionsCollapse ? 'scale-y-100 h-max contents' : 'h-0 scale-y-0 hidden'}>{children}</div>
    </div>
  )
}
