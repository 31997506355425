import { memo, useState, useEffect } from 'react'
import { MlSwatchCard } from '@curran-catalog/curran-atomic-library'

import { FlooringWeaveActionTypes } from 'context'
import { ContentOptionPopover, OptionPopover } from '@components/product-detail-options'
import { useFlooringWeave, useMediaQuery } from 'hooks'
import { isValidUrl, normalizeAsset, renderOptionName } from 'utils'
import { ContentfulObjFlooringWeaveColor } from 'types'
import { PopoverWrapper } from '@components/PopoverWrapper'

interface ColorOptionProps {
  option: ContentfulObjFlooringWeaveColor & { isPopover?: boolean }
  hasSelectedOption: boolean
  onOpenPopover: () => void
  onClosePopover: () => void
}

const ColorOption = memo(({ option, hasSelectedOption, onOpenPopover, onClosePopover }: ColorOptionProps) => {
  const { CONTENTFUL_ID, description, images, subheaderRugBuilder: subheader, isPopover } = option

  const { dispatch } = useFlooringWeave()

  const isDesktop = useMediaQuery('(min-width: 1280px)')

  const [isOpenModal, setIsOpenModal] = useState(false)

  const hasImages = Array.isArray(images) && images.length > 0

  useEffect(() => {
    if (hasSelectedOption) setIsOpenModal(false)
  }, [hasSelectedOption])

  return (
    <li className={`w-fit justify-self-center ${isPopover ? 'col-span-2' : ''}`}>
      {isPopover ? (
        <OptionPopover onClose={() => onClosePopover()}>
          <ContentOptionPopover name={renderOptionName(option)} description={description} images={images} />
        </OptionPopover>
      ) : (
        <div
          onMouseEnter={() => {
            if (!hasSelectedOption) {
              setIsOpenModal(true)
            }
          }}
          onMouseLeave={() => setIsOpenModal(false)}
        >
          <MlSwatchCard
            id={CONTENTFUL_ID}
            disabled={false}
            selected={hasSelectedOption}
            title={renderOptionName(option)}
            description={subheader ?? ''}
            image={
              hasImages && isValidUrl(images[0].secure_url)
                ? normalizeAsset({ asset: images[0] })
                : { src: '/images/image-coming-soon.jpg', alt: '' }
            }
            clickHandler={() => {
              dispatch({
                type: FlooringWeaveActionTypes.SELECT_COLOR,
                colorPayload: { color: option },
              })

              onOpenPopover()
            }}
          />
          {isDesktop && (
            <PopoverWrapper openModal={isOpenModal}>
              <ContentOptionPopover name={renderOptionName(option)} description={description} images={images} />
            </PopoverWrapper>
          )}
        </div>
      )}
    </li>
  )
})

interface ColorOptionsProps {
  options: ContentfulObjFlooringWeaveColor[]
}

export const ColorOptions = ({ options = [] }: ColorOptionsProps) => {
  const { state } = useFlooringWeave()
  const { selectedColor } = state

  return (
    <ul className="grid grid-cols-2 items-center xl:grid-cols-3 gap-4 p-4 w-full max-h-[460px] md:max-h-96 xl:max-h-[500px] overflow-y-scroll">
      {options.map((option, index) => {
        return (
          <ColorOption
            key={`color-weave-list-${index}-${option.CONTENTFUL_ID}`}
            option={option}
            hasSelectedOption={
              selectedColor?.CONTENTFUL_ID ? option?.CONTENTFUL_ID === selectedColor.CONTENTFUL_ID : false
            }
            onOpenPopover={() => false}
            onClosePopover={() => false}
          />
        )
      })}
    </ul>
  )
}
