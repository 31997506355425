import { memo, useMemo, useState } from 'react'
import { MlSwatchCard } from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes, ObjFabricState } from '@context/furniture-product'
import { useFurnitureProduct, useMediaQuery } from 'hooks'
import { renderOptionName, isValidUrl, normalizeAsset, renderOptionPrice } from 'utils'
import { ContentOptionPopover, OptionPopover } from './option-popover'
import { ContentType } from 'types'
import { PopoverWrapper } from '@components/PopoverWrapper'

const highlightSelectedOption = (
  contentType: ContentType,
  option: ObjFabricState,
  selectedFabric?: string,
  selectedMainCushion?: string,
) => {
  switch (contentType) {
    case ContentType.OBJ_FURNITURE_FABRIC_GRADE:
      return selectedFabric ? option?.uniqueID === selectedFabric : false

    case ContentType.OBJ_FURNITURE_MAIN_CUSHION:
      return selectedMainCushion ? option?.uniqueID === selectedMainCushion : false

    default:
      return false
  }
}

const getCheckedFabric = ({
  contentType,
  options,
  fabric,
  mainCushionFabric,
  openPopover,
}: {
  openPopover: boolean
  contentType: ContentType
  options: ObjFabricState[]
  fabric?: ObjFabricState
  mainCushionFabric?: ObjFabricState
}) => {
  switch (contentType) {
    case ContentType.OBJ_FURNITURE_FABRIC_GRADE: {
      const fabricIndex = openPopover
        ? options.findIndex(({ uniqueID, CONTENTFUL_ID }) =>
            uniqueID ? uniqueID === fabric?.uniqueID : CONTENTFUL_ID === fabric?.CONTENTFUL_ID,
          )
        : undefined
      // Convert the fabric checked to popover obj
      const popoverFabricObj: ObjFabricState | undefined = fabric
        ? {
            ...fabric,
            uniqueID: `${fabric?.uniqueID ? `${fabric?.uniqueID}-popover` : `${fabric?.CONTENTFUL_ID}-popover`}`,
            isPopover: true,
          }
        : undefined

      return { fabricIndex, popoverFabricObj }
    }

    case ContentType.OBJ_FURNITURE_MAIN_CUSHION: {
      const fabricIndex = openPopover
        ? options.findIndex(({ uniqueID, CONTENTFUL_ID }) =>
            uniqueID ? uniqueID === mainCushionFabric?.uniqueID : CONTENTFUL_ID === mainCushionFabric?.CONTENTFUL_ID,
          )
        : undefined
      // Convert the fabric checked to popover obj
      const popoverFabricObj: ObjFabricState | undefined = mainCushionFabric
        ? {
            ...mainCushionFabric,
            uniqueID: `${
              mainCushionFabric?.uniqueID
                ? `${mainCushionFabric?.uniqueID}-popover`
                : `${mainCushionFabric?.CONTENTFUL_ID}-popover`
            }`,
            isPopover: true,
          }
        : undefined

      return { fabricIndex, popoverFabricObj }
    }

    default:
      return { fabricIndex: undefined, popoverFabricObj: undefined }
  }
}

interface ColorOptionProps {
  contentType: ContentType
  option: ObjFabricState
  hasSelectedOption: boolean
  onOpenPopover: () => void
  onClosePopover: () => void
}

const ColorOption = memo(
  ({ option, hasSelectedOption, contentType, onOpenPopover, onClosePopover }: ColorOptionProps) => {
    const { price, orderSku, images, subHeader, description, isPopover = false, uniqueID, CONTENTFUL_ID } = option

    const { state, dispatch } = useFurnitureProduct()
    const { cushionMinPrice, startingPrice, isLoadingPrices, productsDisabled } = state

    const isDesktop = useMediaQuery('(min-width: 1280px)')

    const [isOpenModal, setIsOpenModal] = useState(false)

    const hasImages = Array.isArray(images) && images.length > 0

    return (
      <li
        className={`w-fit justify-self-center ${isPopover ? 'col-span-2' : ''}`}
        onClick={() =>
          dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
        }
      >
        {isPopover ? (
          <OptionPopover onClose={() => onClosePopover()}>
            <ContentOptionPopover
              name={renderOptionName(option)}
              description={description}
              price={renderOptionPrice({ contentType, price, startingPrice, cushionMinPrice }).replace(/[()]/g, '')}
              images={images}
            />
          </OptionPopover>
        ) : (
          <div onMouseEnter={() => setIsOpenModal(true)} onMouseLeave={() => setIsOpenModal(false)}>
            <MlSwatchCard
              id={`${uniqueID ? uniqueID : CONTENTFUL_ID}`}
              selected={hasSelectedOption}
              disabled={
                productsDisabled
                  ? true
                  : contentType === ContentType.OBJ_FURNITURE_MAIN_CUSHION
                  ? isLoadingPrices
                  : false
              }
              title={`${renderOptionName(option)} ${renderOptionPrice({
                contentType,
                price,
                startingPrice,
                cushionMinPrice,
              })}`}
              description={subHeader ?? ''}
              image={
                hasImages && isValidUrl(images[0].secure_url)
                  ? normalizeAsset({ asset: images[0] })
                  : { src: '/images/image-coming-soon.jpg', alt: '' }
              }
              clickHandler={() => {
                if (contentType === ContentType.OBJ_FURNITURE_FABRIC_GRADE) {
                  dispatch({
                    type: FurnitureProductActionTypes.SET_FABRIC_GRADE,
                    fabricGrade: option,
                  })
                } else if (contentType === ContentType.OBJ_FURNITURE_MAIN_CUSHION) {
                  dispatch({
                    type: FurnitureProductActionTypes.SET_MAIN_CUSHION,
                    mainCushionFabric: {
                      ...option,
                      price,
                    },
                  })
                  dispatch({
                    type: FurnitureProductActionTypes.SET_MAIN_CUSHION_PRICE_NOT_FOUND,
                    mainCushionPriceNotFound: !price && !orderSku,
                  })
                }

                dispatch({
                  type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
                })

                onOpenPopover()
              }}
            />
            {isDesktop && !isLoadingPrices && (
              <PopoverWrapper openModal={isOpenModal}>
                <ContentOptionPopover
                  name={renderOptionName(option)}
                  description={description}
                  price={renderOptionPrice({ contentType, price, startingPrice, cushionMinPrice }).replace(/[()]/g, '')}
                  images={images}
                />
              </PopoverWrapper>
            )}
          </div>
        )}
      </li>
    )
  },
)

interface ColorOptionsProps {
  contentType: ContentType
  options: ObjFabricState[]
}

export const ColorOptions = ({ contentType, options = [] }: ColorOptionsProps) => {
  const { state } = useFurnitureProduct()
  const { fabric, mainCushionFabric } = state

  const isDesktop = useMediaQuery('(min-width: 1280px)')

  const [openPopover, setOpenPopover] = useState(false)

  const { fabricIndex, popoverFabricObj } = useMemo(
    () => getCheckedFabric({ openPopover, fabric, mainCushionFabric, contentType, options }),
    [contentType, fabric, mainCushionFabric, openPopover, options],
  )

  return (
    <ul className="grid grid-cols-2 items-center xl:grid-cols-3 gap-4 p-4 w-full max-h-[460px] md:max-h-96 xl:max-h-[500px] overflow-y-scroll">
      {(typeof fabricIndex === 'number' && popoverFabricObj && !isDesktop
        ? [
            ...options.slice(0, fabricIndex % 2 === 0 ? fabricIndex : fabricIndex - 1),
            popoverFabricObj,
            ...options.slice(fabricIndex % 2 === 0 ? fabricIndex : fabricIndex - 1),
          ].filter(Boolean)
        : options
      ).map((option, index) => {
        const hasSelectedOption = highlightSelectedOption(
          contentType,
          option,
          fabric?.uniqueID,
          mainCushionFabric?.uniqueID,
        )
        return (
          <ColorOption
            key={`color-grade-list-${index}-${option?.uniqueID ? option?.uniqueID : option?.CONTENTFUL_ID}`}
            contentType={contentType}
            option={option}
            hasSelectedOption={hasSelectedOption}
            onOpenPopover={() => setOpenPopover(true)}
            onClosePopover={() => setOpenPopover(false)}
          />
        )
      })}
    </ul>
  )
}
