import { memo } from 'react'
import { Document, BLOCKS } from '@contentful/rich-text-types'
import { MlRichTextProps, OrCollapseContainer, OrCollapseProps } from '@curran-catalog/curran-atomic-library'
import { getSpecificationOptionsNodes, getWeaveSpecificationsNodes } from '@templates/tm-product-detail/utils'
import { ContentfulObjFlooringWeave, SiteDomain } from 'types'
import { environment } from '@config/environment'

const CollapseSectionComponent = ({ product }: { product: ContentfulObjFlooringWeave }) => {
  const productCopy = JSON.parse(JSON.stringify(product))
  const isSynsisal = environment.siteDomain === SiteDomain.SYNSISAL
  const richTextMock: Document = {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        data: {},
        content: [],
      },
    ],
  }

  if (product.specificationInformation) {
    productCopy.specificationInformation.content[0].content.unshift(
      ...getSpecificationOptionsNodes(product.extraSpecifications),
    )
    productCopy.specificationInformation.content[0].content.unshift(...getWeaveSpecificationsNodes(product))
  } else {
    richTextMock.content[0].content.unshift(...getSpecificationOptionsNodes(product.extraSpecifications))
    richTextMock.content[0].content.unshift(...getWeaveSpecificationsNodes(product))
  }

  const collapseItems: OrCollapseProps[] = []

  collapseItems.push({
    id: 0,
    isOpen: true,
    title: 'Specifications',
    content: [
      product.specificationInformation ? { text: productCopy.specificationInformation } : { text: richTextMock },
    ] as MlRichTextProps[],
  })

  product?.careInformation &&
    collapseItems.push({
      id: 1,
      isOpen: true,
      title: 'Care',
      content: [{ text: product?.careInformation.description }] as MlRichTextProps[],
    })

  product?.installationInformation &&
    collapseItems.push({
      id: 2,
      isOpen: true,
      title: 'Installation',
      content: [{ text: product?.installationInformation.description }] as MlRichTextProps[],
    })

  product?.vendor?.delivery &&
    !isSynsisal &&
    collapseItems.push({
      id: 3,
      idTarget: 'ShippingSpecifications',
      isOpen: true,
      title: 'Shipping',
      content: [
        product.uniqueShippingDescription
          ? { text: product.uniqueShippingDescription }
          : { text: product.vendor.delivery.description },
      ] as MlRichTextProps[],
    })

  return <OrCollapseContainer className="" collapseItems={collapseItems} />
}

export const CollapseSection = memo(CollapseSectionComponent)
