import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Document } from '@contentful/rich-text-types'
import { AxiosError } from 'axios'
import { AtControl, MlFormField, MlRichText, SelectOption } from '@curran-catalog/curran-atomic-library'

import { FlooringWeaveActionTypes } from '@context/flooring-product'
import {
  feetInchesToMConversion,
  getFeetInches,
} from '@components/rug-builder/order-rug/steps/customize-rug-types/utils'
import { useFlooringWeave } from '@hooks/use-flooring-weave'
import { useForm } from '@hooks/use-form'
import { useModal } from '@hooks/use-modal'
import { useWallToWall } from '@hooks/use-wall-to-wall'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { ErrorResponse } from '@services/cart'
import { getWallToWallAreaPrices } from '@services/prices'
import { isPriceFetchingError } from '@utils/price'
import {
  getMeasureUnitImperialOptions,
  getMeasureUnitMetricOptions,
  MeasureUnitKey,
  getSquareMeterOrLinearConversion,
  getSquareYardConversion,
} from '@utils/measures'

export type Values = {
  sqyd: string
  sqft: string
  lft: string
  lftIn: string
  sqm: string
  lm: string
}

const initialValues: Values = {
  sqyd: '',
  sqft: '',
  lft: '',
  lftIn: '',

  sqm: '',
  lm: '',
}

const SizeWallToWall = ({
  sizeDescription,
  rollWidth,
  blMinLength,
}: {
  sizeDescription?: Document
  rollWidth: number
  blMinLength: number
}) => {
  const { state, dispatch } = useFlooringWeave()
  const { weaveId, size } = state

  const { setIsModalErrorOpen } = useModal()

  const submitSizeFields = useRef(false)

  useEffect(() => {
    // Auto-select the first option
    if (!size.isMetric) {
      dispatch({
        type: FlooringWeaveActionTypes.SELECT_UNIT_MEASURE,
        sizeOptionPayload: getMeasureUnitImperialOptions()[0],
      })
    } else {
      dispatch({
        type: FlooringWeaveActionTypes.SELECT_UNIT_MEASURE,
        sizeOptionPayload: getMeasureUnitMetricOptions()[0],
      })
    }
    dispatch({
      type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
      errorSizePayload: { isError: true, errorMessage: '' },
    })
  }, [dispatch, size.isMetric])

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: FlooringWeaveActionTypes.SET_MEASURE_SIZE_FIELDS,
      fieldKeyPayload: name as MeasureUnitKey,
      fieldValuePayload: value,
    })
  }, 200)

  const submitHandler = async () => {
    return getWallToWallAreaPrices({
      weave: weaveId,
      isMetric: size.isMetric,
      unitMeasure: size.unitMeasure?.value as MeasureUnitKey,
      sqYd: Number(size.sqyd ?? 0),
      sqFt: Number(size.sqft),
      lft: Number(size.lft),
      lftIn: Number(size.lftIn ?? 0),
      lm: Number(size.lm ?? 0),
      sqM: Number(size.sqm ?? 0),
    })
      .then(({ data }) => {
        dispatch({
          type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
          errorSizePayload: undefined,
        })

        dispatch({
          type: FlooringWeaveActionTypes.SET_BASE_PRICE,
          basePricePayload: data.price,
        })
      })
      .catch((e: AxiosError<ErrorResponse>) => {
        const showModal = isPriceFetchingError(e)
        if (!showModal) {
          dispatch({
            type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
            errorSizePayload: { isError: true, errorMessage: e.response?.data.message ?? '' },
          })
        } else {
          setIsModalErrorOpen(true)
        }
      })
  }

  const { handleMetricChange, handleUnitMeasure, imperialSchemaValidation, metricSchemaValidation } = useWallToWall()

  const { errors, handleBlur, handleChange, handleSubmit, values, resetValues } = useForm(
    initialValues,
    submitHandler,
    size.isMetric
      ? metricSchemaValidation({
          measureUnit: size.unitMeasure?.value as MeasureUnitKey,
          blMinLength,
          rollWidth,
        })
      : imperialSchemaValidation({
          measureUnit: size.unitMeasure?.value as MeasureUnitKey,
          blMinLength,
          rollWidth,
        }),
    debouncedHandler,
  )

  const { feet, inches } = getFeetInches(rollWidth)

  useEffect(() => {
    if (submitSizeFields.current) {
      if (errors.sqyd || errors.sqft || errors.lft || errors.lftIn || errors.sqm || errors.lm) {
        dispatch({
          type: FlooringWeaveActionTypes.SET_BASE_PRICE,
          basePricePayload: undefined,
        })
        dispatch({
          type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
          errorSizePayload: { isError: true, errorMessage: '' },
        })
      } else {
        handleSubmit()
      }

      submitSizeFields.current = false
    }
  }, [dispatch, errors.lft, errors.lftIn, errors.lm, errors.sqft, errors.sqm, errors.sqyd, handleSubmit])

  return (
    <div className="flex flex-col gap-4 flex-wrap px-4 my-4">
      <div className="flex flex-row justify-between items-center">
        <h3 className="text-lg font-medium py-2">Enter Size</h3>
        <div className="w-28">
          <AtControl
            name="rugMetric"
            label="Metric"
            type="control"
            checked={size.isMetric}
            onChange={() => {
              handleMetricChange()
              resetValues()
            }}
          />
        </div>
      </div>
      {sizeDescription && (
        <div className="text-sm">
          <MlRichText text={sizeDescription} />
        </div>
      )}

      <div className="flex flex-col gap-2 flex-wrap">
        {size.isMetric ? (
          <MlFormField
            type="select"
            labelClassName="font-medium"
            label="Select Unit of Measure:"
            options={getMeasureUnitMetricOptions()}
            currentOption={size.unitMeasure}
            filterHandler={(selectedOption) => {
              resetValues()
              handleUnitMeasure(selectedOption)
            }}
            rightIcon={size.isError ? 'close-circle' : undefined}
          />
        ) : (
          <MlFormField
            labelClassName="font-medium"
            label="Select Unit of Measure:"
            type="select"
            options={getMeasureUnitImperialOptions()}
            currentOption={size.unitMeasure}
            filterHandler={(selectedOption) => {
              resetValues()
              handleUnitMeasure(selectedOption)
            }}
            rightIcon={size.isError ? 'close-circle' : undefined}
          />
        )}
      </div>

      {/* METRIC INPUTS */}
      {size.isMetric && MeasureUnitKey.SQUARE_METER === size.unitMeasure?.value && (
        <MlFormField
          type="number"
          placeholder="Enter Your Measurement"
          label="&nbsp;"
          name="sqm"
          value={values.sqm}
          error={!!errors.sqm || (size.isError && !!size.errorMessage)}
          errorMessage={errors.sqm || size.errorMessage}
          rightIcon={errors.sqm || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e)
            submitSizeFields.current = true
          }}
        />
      )}

      {size.isMetric && MeasureUnitKey.LINEAR_METER === size.unitMeasure?.value && (
        <div className="flex flex-row gap-4">
          <div className="w-3/6">
            <MlFormField
              placeholder=""
              disabled
              label="Roll Width:"
              name="roll_width"
              value={`${feetInchesToMConversion(rollWidth)}m`}
            />
          </div>

          <div className="w-3/6 flex flex-col gap-2">
            <span className="text-sm leading-4 font-medium">Enter Your Length:</span>
            <div className="flex flex-row gap-4 -mt-6">
              <MlFormField
                type="number"
                placeholder="m"
                label="&nbsp;"
                name="lm"
                value={values.lm}
                error={!!errors.lm || (size.isError && !!size.errorMessage)}
                errorMessage={errors.lm || size.errorMessage}
                rightIcon={errors.lm || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e)
                  submitSizeFields.current = true
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* IMPERIAL INPUTS */}
      {!size.isMetric && MeasureUnitKey.SQUARE_YARD === size.unitMeasure?.value && (
        <MlFormField
          type="number"
          placeholder="Enter Your Measurement"
          label="&nbsp;"
          name="sqyd"
          value={values.sqyd}
          error={!!errors.sqyd || (size.isError && !!size.errorMessage)}
          errorMessage={errors.sqyd || size.errorMessage}
          rightIcon={errors.sqyd || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e)
            submitSizeFields.current = true
          }}
        />
      )}

      {!size.isMetric && MeasureUnitKey.SQUARE_FEET === size.unitMeasure?.value && (
        <MlFormField
          type="number"
          placeholder="Enter Your Measurement"
          label="&nbsp;"
          name="sqft"
          value={values.sqft}
          error={!!errors.sqft || (size.isError && !!size.errorMessage)}
          errorMessage={errors.sqft || size.errorMessage}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e)
            submitSizeFields.current = true
          }}
          rightIcon={errors.sqft || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
        />
      )}

      {!size.isMetric && MeasureUnitKey.LINEAR_FEET === size.unitMeasure?.value && (
        <div className="flex flex-row gap-4">
          <div className="w-3/6">
            <MlFormField placeholder="" disabled label="Roll Width:" name="roll_width" value={`${feet}' ${inches}''`} />
          </div>

          <div className="w-3/6 flex flex-col gap-2">
            <span className="text-sm leading-4 font-medium">Enter Your Length:</span>
            <div className="flex flex-row gap-4 md:gap-1 lg:gap-4 -mt-6">
              <div className="w-1/2">
                <MlFormField
                  type="number"
                  placeholder="Ft."
                  name="lft"
                  value={values.lft}
                  error={!!errors.lft || (size.isError && !!size.errorMessage)}
                  errorMessage={errors.lft || size.errorMessage}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e)
                    submitSizeFields.current = true
                  }}
                  label="&nbsp;"
                  rightIcon={errors.lft || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
                />
              </div>

              <div className="w-1/2">
                <MlFormField
                  type="number"
                  placeholder="In."
                  name="lftIn"
                  label="&nbsp;"
                  value={values.lftIn}
                  error={!!errors.lftIn}
                  errorMessage={errors.lftIn}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e)
                    submitSizeFields.current = true
                  }}
                  rightIcon={errors.lftIn || (size.isError && !!size.errorMessage) ? 'close-circle' : undefined}
                />
              </div>
            </div>
            {size.isError && size.errorMessage && (
              <span className="text-error text-sm leading-4 -mt-3">{size.errorMessage}</span>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-row gap-4">
        {MeasureUnitKey.LINEAR_FEET === size.unitMeasure?.value ||
        MeasureUnitKey.LINEAR_METER === size.unitMeasure?.value ? null : (
          <div className="w-3/6">
            <MlFormField
              placeholder=""
              disabled
              label="Roll Width:"
              name="roll_width"
              value={size.isMetric ? `${feetInchesToMConversion(rollWidth)}m` : `${feet}' ${inches}''`}
            />
          </div>
        )}

        <div
          className={
            MeasureUnitKey.LINEAR_FEET === size.unitMeasure?.value ||
            MeasureUnitKey.LINEAR_METER === size.unitMeasure?.value
              ? 'w-full'
              : 'w-3/6'
          }
        >
          {MeasureUnitKey.LINEAR_FEET === size.unitMeasure?.value ? (
            <MlFormField
              placeholder=""
              disabled
              label="Square Yard Conversion:"
              name="sqydConversion"
              value={`${getSquareYardConversion({ rollWidth, lft: size.lft, lftIn: size.lftIn })} ${
                MeasureUnitKey.SQUARE_YARD
              }`}
            />
          ) : (
            <MlFormField
              placeholder=""
              disabled
              label={
                MeasureUnitKey.LINEAR_METER === state.size.unitMeasure?.value
                  ? 'Square Meter Conversion:'
                  : 'Linear Conversion:'
              }
              name="lftConversion"
              value={getSquareMeterOrLinearConversion({
                isMetric: size.isMetric,
                rollWidth,
                unitMeasure: size.unitMeasure?.value as MeasureUnitKey,
                lm: size.lm,
                sqft: size.sqft,
                sqm: size.sqm,
                sqyd: size.sqyd,
              })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const SizeWallCovering = ({ blMinLength, rollWidth }: { blMinLength: number; rollWidth: number }) => {
  const { state, dispatch } = useFlooringWeave()
  const { setIsModalErrorOpen } = useModal()

  const { sizeWallCovering } = state

  const options: SelectOption[] = useMemo(() => [], [])

  const handleWallCoveringSizeChange = useCallback(
    (option: SelectOption) => {
      dispatch({
        type: FlooringWeaveActionTypes.SET_WALL_COVERING_SIZE,
        sizeOptionPayload: option,
      })
      getWallToWallAreaPrices({
        weave: state.weaveId,
        isMetric: state.size.isMetric,
        ...{
          ...(state.size.isMetric
            ? { sqM: Number(option.value) * rollWidth }
            : { sqFt: Number(option.value) * rollWidth }),
        },
      })
        .then(({ data }) => {
          dispatch({
            type: FlooringWeaveActionTypes.SET_BASE_PRICE,
            basePricePayload: data.price,
          })
          dispatch({
            type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
            errorSizePayload: undefined,
          })
        })
        .catch((e: AxiosError<ErrorResponse>) => {
          const showModal = isPriceFetchingError(e)
          if (!showModal) {
            dispatch({
              type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
              errorSizePayload: { isError: true, errorMessage: e.response?.data.message ?? '' },
            })
          } else {
            setIsModalErrorOpen(true)
          }
        })
    },
    [dispatch, rollWidth, setIsModalErrorOpen, state.size.isMetric, state.weaveId],
  )

  useEffect(() => {
    for (let i = blMinLength; i <= 99; i = i + 3) {
      options.push({ value: `${i}`, label: `${i} Lineal Ft` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blMinLength])

  return (
    <div className="flex flex-col gap-4 flex-wrap px-4 my-4">
      <MlFormField
        error={state.size.isError}
        errorMessage={state.size.errorMessage}
        type="select"
        placeholder="Select Lineal Feet"
        options={options}
        currentOption={sizeWallCovering}
        filterHandler={handleWallCoveringSizeChange}
        rightIcon={state.size.isError ? 'close-circle' : undefined}
      />
    </div>
  )
}

export const SizeField = ({
  isWallToWall,
  sizeDescription,
  blMinLength,
  rollWidth,
}: {
  isWallToWall: boolean
  sizeDescription?: Document
  blMinLength: number
  rollWidth: number
}) => {
  if (isWallToWall)
    return (
      <SizeWallToWall
        key="size-field-wall-to-wall"
        sizeDescription={sizeDescription}
        rollWidth={rollWidth}
        blMinLength={blMinLength}
      />
    )
  return <SizeWallCovering blMinLength={blMinLength} rollWidth={rollWidth} />
}
