import { trackAnalyticsEvent } from '@utils/analytics/events/track-event'
import { CartItem } from '../../../../types'
import { SampleCartDTO } from '@services/cart'
import { CartEventDTO, CartEventTypes } from '../../../../types/analytics/analytics-data'
import { createAddToCartDTO } from '@utils/analytics/events/cart/add-to-cart'
import { detectSiteDomain } from '@utils/detect-site-domain'
import { createViewCartDTO } from '@utils/analytics/events/cart/view-cart'

/**
 * Tracks an event by sending it into the datalayer, specifically events related
 * to product analytics such as: view_item, view_item_list, and select_item
 * @param inputData
 * @param eventType
 * @param index
 * @param quantity
 * @param previousRoute
 * @param productName
 */
export const sendCartAnalyticsEvent = (
  inputData: CartItem | SampleCartDTO | CartItem[],
  eventType: CartEventTypes,
  index?: number,
  quantity?: number,
  previousRoute?: string,
  productName?: string,
) => {
  if (!inputData) {
    return
  }

  const siteDomainInfo = detectSiteDomain()

  let cartEventDTO: CartEventDTO

  switch (eventType) {
    case CartEventTypes.ADD_TO_CART:
      cartEventDTO = createAddToCartDTO(
        inputData,
        CartEventTypes.ADD_TO_CART,
        siteDomainInfo,
        index,
        quantity,
        previousRoute,
        productName,
      )
      break
    case CartEventTypes.REMOVE_FROM_CART:
      cartEventDTO = createAddToCartDTO(
        inputData,
        CartEventTypes.REMOVE_FROM_CART,
        siteDomainInfo,
        index,
        quantity,
        previousRoute,
        productName,
      )
      break
    case CartEventTypes.VIEW_CART:
      cartEventDTO = createViewCartDTO(inputData as CartItem[], siteDomainInfo, previousRoute)
      break
  }

  if (cartEventDTO) {
    trackAnalyticsEvent(cartEventDTO)
  }
}
