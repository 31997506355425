import { Document } from '@contentful/rich-text-types'
import {
  OrSampleContainer,
  MlSampleCardProps,
  AtImageProps,
  OrSampleGroup,
  OrModal,
  OrModalProps,
} from '@curran-catalog/curran-atomic-library'
import { SamplesOrderPreview } from '@components/flooring-product-detail'
import {
  ContentfulObjFlooringWeaveColorGroup,
  ContentfulObjFlooringBorderGroup,
  ContentfulObjFlooringBorderColor,
  ContentfulObjFlooringWeaveColor,
  ContentfulMlRichText,
  CartItem,
} from 'types'
import { normalizeAsset } from '@utils/normalize'
import { useState } from 'react'
import { normalizeRichText } from '@utils/normalize/rich-text'
import { getPopoverRichText } from '@utils/get-custom-richtext'
import { SampleCartDTO } from '@services/cart'
import { useModal } from '@hooks/use-modal'
import { useRouter } from 'next/router'
import { getErrorModalProps } from '@config/modal-error'
import { formatPrice } from '@utils/price'
import { useCart } from '@hooks/index'
import { ObjFlooringWeave } from '@services/prices'

export type OrderSamplesProps = {
  titleColors?: string
  colors: ContentfulObjFlooringWeaveColorGroup
  hasColorSamples: boolean
  priceSample: number | null
  titleBorders?: string
  borders: ContentfulObjFlooringBorderGroup
  priceBorderSample: number | null
  weave: ObjFlooringWeave
}

export type HandleClickProps = {
  id: string
  index: number
  borderId?: string
  borderName?: string
  name: string
  type: TypeSample
  addToCart: boolean
  img: { src: string; alt: string }
  weaveId: string
  cartId?: number
}

export enum TypeSample {
  WEAVE = 'weave',
  BORDER = 'border',
}

interface NormalizeSampleCardProps {
  index: number
  item: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor
  itemName: string
  type: TypeSample
  handleClick: ({ id, borderId, borderName, name, type, addToCart }: HandleClickProps) => void
  cartSample: CartItem | undefined
  weaveId: string
  subheader?: string
  description?: string
  outOfStockLabel?: string
  samplesInStock?: boolean
  borderId?: string
  borderName?: string
}

export const normalizeSampleCard = ({
  index,
  item,
  itemName,
  subheader,
  samplesInStock = true,
  type,
  handleClick,
  cartSample,
  weaveId,
  borderId,
  borderName,
  description,
}: NormalizeSampleCardProps) => {
  const sampleImage =
    Array.isArray(item.images) && item.images.length > 0
      ? { ...(normalizeAsset({ asset: item.images[0] }) as AtImageProps), caption: undefined }
      : { src: '/images/image-coming-soon.jpg', alt: '' }
  return {
    id: item.CONTENTFUL_ID,
    title: itemName,
    subtitle: subheader,
    description: description,
    isOutOfStock: !samplesInStock,
    isAddedToCart: Boolean(cartSample),
    image:
      Array.isArray(item.images) && item.images.length > 0
        ? { ...(normalizeAsset({ asset: item.images[0] }) as AtImageProps), caption: undefined }
        : { src: '/images/image-coming-soon.jpg', alt: '' },
    onClick(_id, isAdded) {
      return handleClick({
        index,
        id: item.CONTENTFUL_ID,
        ...(borderId && { borderId }),
        ...(borderName && { borderName }),
        name: itemName,
        type,
        addToCart: isAdded,
        img: { src: sampleImage.src as string, alt: sampleImage.alt as string },
        cartId: cartSample?.id,
        weaveId,
      })
    },
  } as MlSampleCardProps
}

export const OrderSamples = ({
  titleColors,
  colors,
  titleBorders,
  borders,
  hasColorSamples,
  priceSample,
  priceBorderSample,
  weave,
}: OrderSamplesProps) => {
  const router = useRouter()
  const { isModalErrorOpen, setIsModalErrorOpen } = useModal()
  const { addSampleToCart, cart, isLoading, removeItem } = useCart()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [infoModal, setInfoModal] = useState<OrModalProps>({
    title: '',
    handleCloseModal: () => setIsOpenModal(false),
  })

  const sampleCartItem = (sampleInfo: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor) => {
    return cart?.items.find(
      (itemCart) => itemCart.metadata && itemCart.metadata.contentful_id === sampleInfo.CONTENTFUL_ID,
    )
  }

  const handleToggleSample = async ({
    id,
    name,
    type,
    addToCart,
    img,
    weaveId,
    borderId,
    cartId,
    borderName,
  }: HandleClickProps) => {
    if (addToCart) {
      const sampleInfo: SampleCartDTO = {
        color: name,
        type,
        quantity: 1,
        weaveReference: weaveId,
        ...(type === TypeSample.BORDER && { borderReference: borderId, borderName }),
        imageAlt: img.alt,
        imageSrc: img.src,
        metadata: {
          contentful_id: id,
          type: 'sample',
          subType: type,
          link: window.location.href,
        },
      }
      try {
        await addSampleToCart(sampleInfo)
      } catch (e) {
        setIsModalErrorOpen(true)
        throw new Error('Not possible to add sample')
      }
    } else {
      try {
        if (cartId) removeItem({ id: cartId as number })
        else throw new Error('Not possible to remove sample')
      } catch (e) {
        setIsModalErrorOpen(true)
        throw new Error('Not possible to remove sample')
      }
    }
  }

  const colorSamples = normalizeColorSamples({
    hasColorSamples,
    colors,
    handleToggleSample,
    sampleCartItem,
    weaveContentfulId: weave.CONTENTFUL_ID,
  })

  const borderSamples = normalizeBordersSamples({
    borders,
    handleToggleSample,
    sampleCartItem,
    weaveContentfulId: weave.CONTENTFUL_ID,
    setInfoModal,
    infoModal,
    setIsOpenModal,
  })

  const priceSampleLabel = formatPrice(priceSample ?? 0)
  const priceBorderSampleLabel = formatPrice(priceBorderSample ?? 0)
  const errorModalProps = getErrorModalProps(
    () => {
      setIsModalErrorOpen(false)
      router.push('/customer-service')
    },
    () => {
      setIsModalErrorOpen(false)
    },
  )

  return (
    <>
      {!isLoading && hasColorSamples && colorSamples && (
        <OrSampleContainer
          titleContainer={
            titleColors ? `${titleColors} (${priceSampleLabel} each)` : `Rug Weave Samples (${priceSampleLabel} each)`
          }
          samplesGroup={colorSamples}
        />
      )}
      {!isLoading && borderSamples && borderSamples.length > 0 && (
        <OrSampleContainer
          titleContainer={
            titleBorders
              ? `${titleBorders} (${priceBorderSampleLabel} each)`
              : `Rug Border Samples (${priceBorderSampleLabel} each)`
          }
          samplesGroup={borderSamples}
        />
      )}
      <OrModal isOpen={isOpenModal} {...infoModal} />

      <SamplesOrderPreview
        colorRugs={colors}
        borderRugs={borders}
        samplePrice={priceSample}
        sampleBorderPrice={priceBorderSample}
      />

      <OrModal
        isOpen={isModalErrorOpen}
        title="Error"
        description={errorModalProps.description}
        leftButton={errorModalProps.leftButton}
        rightButton={errorModalProps.rightButton}
        handleCloseModal={() => setIsModalErrorOpen(false)}
      />
    </>
  )
}

export type NormalizeColorSamplesProps = {
  hasColorSamples: boolean
  colors: ContentfulObjFlooringWeaveColorGroup
  handleToggleSample: ({
    id,
    name,
    type,
    addToCart,
    img,
    weaveId,
    borderId,
    cartId,
    borderName,
  }: HandleClickProps) => Promise<void>
  sampleCartItem: (
    sampleInfo: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor,
  ) => CartItem | undefined
  weaveContentfulId: string
}
export const normalizeColorSamples = ({
  hasColorSamples,
  colors,
  handleToggleSample,
  sampleCartItem,
  weaveContentfulId,
}: NormalizeColorSamplesProps) => {
  return hasColorSamples
    ? ([
        {
          samples: colors?.weaveColors
            ?.filter((color) => color.status === 'Active')
            .map((color, index) => {
              return normalizeSampleCard({
                index,
                item: color,
                itemName: color.weaveColorName,
                subheader: color.subheader || '',
                description: color.subheaderSamples || '',
                samplesInStock: color.samplesInStock,
                type: TypeSample.WEAVE,
                handleClick: handleToggleSample,
                cartSample: sampleCartItem(color),
                weaveId: weaveContentfulId,
              })
            }),
        },
      ] as OrSampleGroup[])
    : []
}

type NormalizeBordersSamplesProps = {
  borders: ContentfulObjFlooringBorderGroup
  handleToggleSample: ({
    id,
    name,
    type,
    addToCart,
    img,
    weaveId,
    borderId,
    cartId,
    borderName,
  }: HandleClickProps) => Promise<void>
  sampleCartItem: (
    sampleInfo: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor,
  ) => CartItem | undefined
  weaveContentfulId: string
  setInfoModal: (v: OrModalProps) => void
  infoModal: OrModalProps
  setIsOpenModal: (s: boolean) => void
}

export const normalizeBordersSamples = ({
  borders,
  handleToggleSample,
  sampleCartItem,
  weaveContentfulId,
  setInfoModal,
  infoModal,
  setIsOpenModal,
}: NormalizeBordersSamplesProps) => {
  const borderSamples: OrSampleGroup[] = []

  borders?.borders?.forEach((borderMaterial) => {
    borderMaterial.hasSamples &&
      borderMaterial?.borderColorGroup?.borderColors &&
      borderSamples.push({
        title: borderMaterial.borderName,
        samples: borderMaterial?.borderColorGroup?.borderColors
          ?.filter((color) => color.status === 'Active')
          .map((border, index) =>
            normalizeSampleCard({
              index,
              item: border,
              itemName: border.borderColorName,
              subheader: border.subheaderSamples || '',
              samplesInStock: border.samplesInStock,
              type: TypeSample.BORDER,
              handleClick: handleToggleSample,
              cartSample: sampleCartItem(border),
              weaveId: weaveContentfulId,
              borderId: borderMaterial.CONTENTFUL_ID,
              borderName: borderMaterial.borderName,
            }),
          ) as MlSampleCardProps[],
        onClickInformationIcon: () => {
          setInfoModal({
            ...infoModal,
            description: {
              ...normalizeRichText({
                content: getPopoverRichText({
                  description: borderMaterial.description,
                  images: borderMaterial.images,
                  title: borderMaterial.borderName,
                }) as Document,
              } as ContentfulMlRichText),
              className: 'w-full',
            },
          })
          setIsOpenModal(true)
          return {}
        },
      })
  })

  return borderSamples
}
