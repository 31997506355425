import { focusElementClasses, FocusElement } from '@curran-catalog/curran-atomic-library'

export interface EditButtonProps {
  edit: boolean
  enable?: boolean
  handleEdit: () => void
}
export const EditButton = ({ edit, handleEdit, enable = true }: EditButtonProps) => (
  <button
    className={`${
      enable
        ? `${focusElementClasses[FocusElement.FOCUS_VISIBLE]} text-secondary`
        : 'text-light-disabled cursor-not-allowed'
    } w-fit underline`}
    type="button"
    onClick={handleEdit}
    disabled={!enable}
  >
    {edit ? 'Cancel' : 'Edit'}
  </button>
)
