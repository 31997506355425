import { CartItem } from '../../../../types'
import { DetectSiteDomain } from '@utils/detect-site-domain'
import { CartEventTypes } from '../../../../types/analytics/analytics-data'
import { SampleCartDTO } from '@services/cart'
import { isSampleCartDTO } from '@utils/type-guards/is-sample-cart-DTO'
import { cartItemItemToDTO, cartItemToDTO, sampleCartToDTO } from '@utils/analytics/transformations/cart'

export const createAddToCartDTO = (
  data: CartItem | SampleCartDTO | CartItem[],
  event: CartEventTypes,
  siteDomainInfo: DetectSiteDomain,
  index?: number,
  quantity?: number,
  previousRoute?: string,
  productName?: string,
) => {
  if (isSampleCartDTO(data)) {
    return sampleCartToDTO(data, event, index, quantity, previousRoute, productName)
  }

  // Cart Item[]
  if (Array.isArray(data)) {
    return {
      event: event,
      ecommerce: {
        currency: 'USD',
        payment_type: 'credit card',
        items: data.map((item, index) => cartItemItemToDTO(item, siteDomainInfo, index, item.quantity, previousRoute)),
      },
    }
  }

  // Cart item
  return cartItemToDTO(data, event, siteDomainInfo, index, quantity, previousRoute)
}
