import { ReactNode } from 'react'
import { AtIcon, AtImage, MlRichText } from '@curran-catalog/curran-atomic-library'
import { Document } from '@contentful/rich-text-types'

import { normalizeAsset } from '@utils/normalize'
import { isValidUrl } from '@utils/url'

import { CloudinaryAsset } from 'types'

export const renderImage = (images: CloudinaryAsset[] = []) => {
  if (Array.isArray(images) && images.length > 0) {
    if (isValidUrl(images[0].secure_url)) {
      return (
        <AtImage
          {...normalizeAsset({ asset: images[0] })}
          caption={undefined}
          className="aspect-video h-40 w-full grid"
        />
      )
    }
  }

  return <AtImage src="/images/image-coming-soon.jpg" alt="" className="aspect-video h-40 w-full" />
}

export const ContentOptionPopover = ({
  name,
  images,
  description,
  price = '',
}: {
  name: string
  images?: CloudinaryAsset[]
  description?: Document
  price?: string
}) => {
  return (
    <>
      {renderImage(images)}

      <div className="flex flex-col gap-2 text-sm text-secondary">
        <h5 className="font-medium">{name}</h5>
        {description ? <MlRichText text={description} className="text-xs gap-1" /> : null}
        {price ? <span className="font-medium">{price}</span> : null}
      </div>
    </>
  )
}

export const OptionPopover = ({ children, onClose }: { children: ReactNode; onClose: () => void }) => {
  return (
    <div className="bg-white border-outlined-gray border w-full max-w-xs h-fit">
      <div className="p-2 md:p-4 flex flex-col gap-4">
        <button className="self-end" aria-label="Close dialog" type="button" onClick={() => onClose()}>
          <AtIcon size={16} type="close" color="secondary" />
        </button>

        {children}
      </div>
    </div>
  )
}
