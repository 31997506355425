import { OrSwatchCardsSlider } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useValidatePreviewSteps } from '@components/rug-builder/order-rug/steps/preview-confirm-sections/hooks'
import { normalizeAsset } from '@utils/normalize'
import { ContentfulObjFlooringWeaveColorGroup, OrderRugStepperIndexes } from 'types'
import { ContentfulComponentWithImages } from '@utils/common'

interface WeaveColorOptionsProps {
  isLoading?: boolean
  options: ContentfulObjFlooringWeaveColorGroup['weaveColors']
}

export const MAX_WIDTH_TABLET_SCREEN_SIZE = 768
export const PREVIEW_CONFIRM_STEP_SWATCH_SLIDES = 2.5
export const COMMON_STEP_SWATCH_SLIDES = 4

export const WeaveColorOptions = ({ isLoading = false, options = [] }: WeaveColorOptionsProps) => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, customize } = state

  const { handleCollapse, openNextCollapse } = useValidatePreviewSteps({
    steps: ['size', 'border', 'addons', 'notes'],
    currentStep: 'weaveColor',
    nextStep: 'size',
  })

  return (
    <div
      className={`w-full h-60 ${
        activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM
          ? 'md:max-w-md lg:max-w-2xl xl:max-w-3xl'
          : 'max-w-3xl xl:max-w-4xl'
      }`}
    >
      <OrSwatchCardsSlider
        breakpoints={{
          [MAX_WIDTH_TABLET_SCREEN_SIZE]: {
            slidesPerView:
              activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM
                ? PREVIEW_CONFIRM_STEP_SWATCH_SLIDES
                : COMMON_STEP_SWATCH_SLIDES,
          },
        }}
        swatchCardSelected={customize.weaveColor?.CONTENTFUL_ID ?? ''}
        swatchCardHandler={(option) => {
          const foundWeaveColor = options.find((optionItem) => optionItem.CONTENTFUL_ID === option.id)
          dispatch({
            type: RugBuilderActionTypes.SET_CUSTOMIZE_WEAVE_COLOR,
            weaveColorPayload: foundWeaveColor,
          })

          if (activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM) {
            dispatch({
              type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
              keyPreviewConfirmPayload: 'isBorderCollapseComplete',
              valuePreviewConfirmPayload: false,
            })

            dispatch({
              type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
              keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
              valuePreviewConfirmPayload: false,
            })

            dispatch({
              type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE,
              isCompletePayload: true,
            })

            dispatch({
              type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
              isCompletePayload: true,
            })

            handleCollapse()
            openNextCollapse()
          }
        }}
        options={options
          .map(({ CONTENTFUL_ID, weaveColorName, subheaderRugBuilder, subheader, images, status }) => ({
            disabled: isLoading,
            status,
            title: weaveColorName,
            id: CONTENTFUL_ID,
            subtitle: subheader ?? '',
            description: subheaderRugBuilder ?? '',
            selected: false,
            image:
              images && Array.isArray(images) && images.length > 0
                ? normalizeAsset({ asset: images[0], contentType: ContentfulComponentWithImages.colorSelector })
                : { src: '/images/image-coming-soon.jpg', alt: '', caption: undefined },
            clickHandler: () => ({}),
          }))
          .filter((option) => option.status !== 'Inactive')}
      />
    </div>
  )
}
