import { FlooringWeaveActionTypes } from '@context/flooring-product'
import { RugBuilderActionTypes } from '@context/rug-builder'
import { AtImageProps, MlColorSlider, MlColorSwatch } from '@curran-catalog/curran-atomic-library'
import { useFlooringWeave, useRugBuilder } from '@hooks/index'
import { ContentfulComponentWithImages } from '@utils/common'
import { normalizeAsset } from '@utils/index'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { ContentfulObjFlooringWeaveColor } from 'types'

export interface ColorSliderProps {
  weaveColors: ContentfulObjFlooringWeaveColor[]
  showSwatch?: boolean
}

export const normalizeColorsToImageProps = (
  color: ContentfulObjFlooringWeaveColor,
  showCaption?: boolean,
): AtImageProps => {
  return color.images && color.images.length > 0
    ? {
        ...(normalizeAsset({
          asset: color.images[0],
          contentType: ContentfulComponentWithImages.imageGallery,
        }) as AtImageProps),
        alt: color.weaveColorName,
        ...(showCaption && { caption: color.subheaderRugBuilder ? `(${color.subheaderRugBuilder})` : '' }),
      }
    : { src: '/images/image-coming-soon.jpg', alt: '' }
}

export const ColorSlider = ({ weaveColors, showSwatch }: ColorSliderProps) => {
  const [color, setColor] = useState(-1)
  const [colorCaption, setColorCaption] = useState('none')
  const { dispatch } = useRugBuilder()
  const {
    dispatch: dispatchFlooring,
    state: { currentColorIndex, selectedColor },
  } = useFlooringWeave()

  const colors = useMemo(() => {
    if (weaveColors) {
      return weaveColors
        .filter((color) => color.status === 'Active')
        .map((color) => normalizeColorsToImageProps(color, !!showSwatch))
    }
    return []
  }, [weaveColors, showSwatch])

  const onColorSelected = useCallback(
    (i: number) => {
      setColor(i)
      const image = colors[i]
      const foundWeaveColor = weaveColors.find((x) => x.weaveColorName == image.alt)
      if (foundWeaveColor) {
        dispatch({
          type: RugBuilderActionTypes.SET_CUSTOMIZE_WEAVE_COLOR,
          weaveColorPayload: foundWeaveColor,
        })

        dispatchFlooring({
          type: FlooringWeaveActionTypes.SELECT_COLOR,
          colorPayload: { color: foundWeaveColor },
        })
        setColorCaption(foundWeaveColor.weaveColorName)
      }
    },
    [colors, weaveColors, dispatch, dispatchFlooring],
  )

  const onColorSelectedByCaption = useCallback(
    (caption: string) => {
      const image = colors.find((x) => x.alt == caption)
      if (!image) return
      const foundWeaveColor = weaveColors.find((x) => x.weaveColorName == image.alt)
      if (foundWeaveColor) {
        dispatch({
          type: RugBuilderActionTypes.SET_CUSTOMIZE_WEAVE_COLOR,
          weaveColorPayload: foundWeaveColor,
        })

        dispatchFlooring({
          type: FlooringWeaveActionTypes.SELECT_COLOR,
          colorPayload: { color: foundWeaveColor },
        })
        setColorCaption(foundWeaveColor.weaveColorName)
      }
    },
    [colors, weaveColors, dispatch, dispatchFlooring],
  )

  useEffect(() => {
    if (currentColorIndex == -1) {
      setColor(0)
      const image = colors[0]
      const foundWeaveColor = weaveColors.find((x) => x.weaveColorName == image.alt)
      if (foundWeaveColor) {
        setColorCaption(foundWeaveColor.weaveColorName)
        setTimeout(
          () =>
            dispatch({
              type: RugBuilderActionTypes.SET_CUSTOMIZE_WEAVE_COLOR,
              weaveColorPayload: foundWeaveColor,
            }),
          500,
        )
        setTimeout(
          () =>
            dispatchFlooring({
              type: FlooringWeaveActionTypes.SELECT_COLOR,
              colorPayload: {
                color: foundWeaveColor,
                displayWeaveColor: false,
              },
            }),
          500,
        )
      }
    }
  }, [currentColorIndex, colors, weaveColors, dispatch, dispatchFlooring])

  useEffect(() => {
    if (currentColorIndex != undefined) {
      setColor(currentColorIndex)
    }
  }, [currentColorIndex])

  useEffect(() => {
    if (selectedColor) {
      setColorCaption(selectedColor.weaveColorName)
    }
  }, [selectedColor])

  return (
    <div className="flex relative w-full">
      {showSwatch ? (
        <MlColorSwatch
          colorImages={colors}
          selectedColor={colorCaption}
          onImageClick={(i) => {
            onColorSelectedByCaption(i)
          }}
        />
      ) : (
        <MlColorSlider
          colorImages={colors}
          selectedColor={color}
          onImageClick={(i) => {
            onColorSelected(i)
          }}
        />
      )}
    </div>
  )
}
