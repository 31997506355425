import { CartItem } from '../../../../types'
import { CartEventDTO, CartEventTypes } from '../../../../types/analytics/analytics-data'
import { DetectSiteDomain } from '@utils/detect-site-domain'
import { cartItemItemToDTO } from '@utils/analytics/transformations/cart'

export const createViewCartDTO = (
  data: CartItem[],
  siteDomainInfo: DetectSiteDomain,
  previousRoute?: string,
): CartEventDTO => {
  // Cart item - Synsisal Only
  return {
    event: CartEventTypes.VIEW_CART,
    ecommerce: {
      currency: 'USD',
      items: data.map((item, index) => cartItemItemToDTO(item, siteDomainInfo, index, item.quantity, previousRoute)),
    },
  }
}
