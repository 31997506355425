import { memo } from 'react'

import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { MlProductOptionsCollapse, RadioPresetFabricOptions } from '@components/product-detail-options'
import { ContentfulObjFurnitureFabricGrade, FABRIC_GRADES_DEFAULT_TITLE } from 'types'

interface PresetFabricsProps {
  initialToggleCollapse: boolean
  fabricGradesTitle?: string
  fabricGrades: ContentfulObjFurnitureFabricGrade[]
  collapsePosition: string[]
}

export const PresetFabrics = memo(
  ({ initialToggleCollapse, fabricGradesTitle, fabricGrades = [], collapsePosition }: PresetFabricsProps) => {
    const { state } = useFurnitureProduct()
    const { presetFabricGrade } = state

    return (
      <MlProductOptionsCollapse
        initialToggleCollapse={initialToggleCollapse}
        index={collapsePosition.findIndex((option) => option === 'presetFabrics') + 1}
        isRequired={true}
        name={fabricGradesTitle ?? FABRIC_GRADES_DEFAULT_TITLE}
        optionSelected={presetFabricGrade ? presetFabricGrade.fabricGradeName : ''}
      >
        <RadioPresetFabricOptions fabricGrades={fabricGrades} />
      </MlProductOptionsCollapse>
    )
  },
)
