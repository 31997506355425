import { useState, useEffect } from 'react'
import { formatPrice } from '@utils/price'
import { ContentfulObjFlooringWeaveColorGroup, ContentfulObjFlooringBorderGroup, CartItem } from 'types'
import { useCart } from '@hooks/index'
import { Sample } from '@context/flooring-samples'
import { TypeSample } from '@components/rug-builder/order-samples'
import { AtButton, AtButtonState, AtButtonVariant } from '@curran-catalog/curran-atomic-library'
import { useRouter } from 'next/router'

const getSampleColor = (sampleToSearch: string, sampleList: ContentfulObjFlooringWeaveColorGroup) =>
  sampleList?.weaveColors?.find((item) => item.CONTENTFUL_ID === sampleToSearch)

const getSampleBorder = (sampleToSearch: string, sampleList: ContentfulObjFlooringBorderGroup) =>
  sampleList?.borders?.map((borderMaterial) =>
    Boolean(
      borderMaterial.hasSamples &&
        borderMaterial.borderColorGroup &&
        borderMaterial.borderColorGroup.borderColors &&
        borderMaterial.borderColorGroup.borderColors.length > 0 &&
        borderMaterial.borderColorGroup.borderColors.find((item) => item.CONTENTFUL_ID === sampleToSearch),
    ),
  )

export const SamplesOrderPreview = ({
  colorRugs,
  borderRugs,
  samplePrice,
  sampleBorderPrice,
}: {
  colorRugs: ContentfulObjFlooringWeaveColorGroup
  borderRugs: ContentfulObjFlooringBorderGroup
  samplePrice: number | null
  sampleBorderPrice: number | null
}) => {
  const { cart, isLoading, isValidating } = useCart()
  const [total, setTotal] = useState<number>(0)
  const [rugSamples, setRugSamples] = useState<Sample[]>([])
  const [borderSamples, setBorderSamples] = useState<Sample[]>([])

  const router = useRouter()
  const [isCartPageLoading, setCartPageLoading] = useState(false)

  useEffect(() => {
    let rugSamplesCart: Sample[] = []
    let borderSamplesCart: Sample[] = []

    cart?.items?.forEach((itemCart: CartItem) => {
      const { metadata } = itemCart
      if (metadata?.contentful_id && metadata?.type && metadata?.subType) {
        if (metadata.type === 'sample' && metadata.subType === TypeSample.WEAVE) {
          const sampleColor = getSampleColor(metadata.contentful_id, colorRugs)
          if (sampleColor) {
            const sampleInfo: Sample = {
              cartId: cart.id,
              id: metadata.contentful_id,
              name: itemCart.description,
              sku: itemCart.sku,
            }
            rugSamplesCart = [...rugSamplesCart, sampleInfo]
          }
        }
        if (metadata.type === 'sample' && metadata.subType === TypeSample.BORDER) {
          const sampleBorder = getSampleBorder(metadata.contentful_id, borderRugs)
          if (sampleBorder) {
            const sampleWeaveInfo: Sample = {
              cartId: cart.id,
              id: metadata.contentful_id,
              name: itemCart.description,
              sku: itemCart.sku,
            }
            borderSamplesCart = [...borderSamplesCart, sampleWeaveInfo]
          }
        }
      }
    })
    setRugSamples(rugSamplesCart)
    setBorderSamples(borderSamplesCart)

    const rugSamplesTotal = rugSamplesCart && samplePrice ? rugSamplesCart.length * samplePrice : 0
    const borderSamplesTotal = borderSamplesCart && sampleBorderPrice ? borderSamplesCart.length * sampleBorderPrice : 0
    setTotal(rugSamplesTotal + borderSamplesTotal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, isLoading, isValidating])

  const priceSampleLabel = formatPrice(samplePrice ?? 0)
  const priceBorderSampleLabel = formatPrice(sampleBorderPrice ?? 0)
  if (isLoading || (rugSamples.length === 0 && borderSamples.length === 0)) return null

  return (
    <div>
      <h2 className="tw-text-xl tw-font-medium">Samples Selected</h2>
      <hr className="tw-text-outlined-gray mt-4" />
      {rugSamples && rugSamples?.length > 0 && (
        <div className="my-4">
          <p>{colorRugs.weaveColorGroupName}</p>
          <ul>
            {rugSamples?.map((sample) => (
              <li key={`sample-color-order-item-${sample.id}`}>
                <span className="tw-text-lg tw-font-medium">
                  {getSampleColor(sample.id, colorRugs) &&
                    `${getSampleColor(sample.id, colorRugs)?.weaveColorName} (${priceSampleLabel})`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {borderSamples &&
        borderRugs &&
        borderSamples?.length > 0 &&
        borderRugs.borders &&
        borderRugs.borders.length > 0 &&
        borderRugs.borders?.map(
          (borderMaterial) =>
            borderMaterial.hasSamples &&
            borderMaterial.borderColorGroup &&
            borderMaterial.borderColorGroup.borderColors &&
            borderMaterial.borderColorGroup.borderColors.length > 0 &&
            borderMaterial.borderColorGroup.borderColors.some((border) =>
              borderSamples?.find((sampleState) => sampleState.id === border.CONTENTFUL_ID),
            ) && (
              <div key={`sample-border-order-item-${borderMaterial.CONTENTFUL_ID}`} className="my-4">
                <p>{borderMaterial.borderName}</p>
                <ul>
                  {borderMaterial.borderColorGroup.borderColors.map(
                    (border) =>
                      border.CONTENTFUL_ID ===
                        borderSamples?.find((sample) => sample.id === border.CONTENTFUL_ID)?.id && (
                        <li key={`sample-order-item-${border.CONTENTFUL_ID}`}>
                          <span className="tw-text-lg tw-font-medium">
                            {border.borderColorName} ({priceBorderSampleLabel})
                          </span>
                        </li>
                      ),
                  )}
                </ul>
              </div>
            ),
        )}

      <hr className="tw-text-outlined-gray" />

      {/* Grey box containing Subtotal & View Cart button */}
      <div className="bg-subdued py-8 px-4 flex justify-between items-center">
        <span className="text-lg font-medium">Subtotal: {formatPrice(total)}</span>

        <AtButton
          label="View Cart"
          variant={AtButtonVariant.PRIMARY}
          onClick={() => {
            setCartPageLoading(true)
            router.push('/cart')
          }}
          state={isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED}
        />
      </div>
    </div>
  )
}
