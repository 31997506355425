import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { FocusElement, focusElementClasses } from '@curran-catalog/curran-atomic-library'

interface ShowMoreProps {
  initialNumberItemsShow: number
  numberItemsShown: number
  itemsLength: number
  handleNumberItemsToShown: Dispatch<SetStateAction<number>>
}

export const ShowMore = ({
  initialNumberItemsShow,
  itemsLength,
  numberItemsShown,
  handleNumberItemsToShown,
}: ShowMoreProps) => {
  const showMore = useCallback(
    () => handleNumberItemsToShown((prevState) => prevState + initialNumberItemsShow),
    [handleNumberItemsToShown, initialNumberItemsShow],
  )
  const showLess = useCallback(
    () => handleNumberItemsToShown(initialNumberItemsShow),
    [handleNumberItemsToShown, initialNumberItemsShow],
  )

  return (
    <>
      {numberItemsShown < itemsLength ? (
        <button
          className={`${focusElementClasses[FocusElement.FOCUS_VISIBLE]} w-fit underline`}
          type="button"
          onClick={showMore}
        >
          Show More ({itemsLength - numberItemsShown})
        </button>
      ) : (
        <button
          className={`${focusElementClasses[FocusElement.FOCUS_VISIBLE]} w-fit underline disabled:hidden`}
          type="button"
          disabled={itemsLength <= initialNumberItemsShow}
          onClick={showLess}
        >
          Show Less
        </button>
      )}
    </>
  )
}
