import { AtButtonVariant, getRichTextNode } from '@curran-catalog/curran-atomic-library'

export const getErrorModalProps = (leftButtonOnClick: () => void, rightButtonOnClick: () => void) => {
  return {
    description: {
      text: getRichTextNode('Something went wrong. Try again or contact customer service.', 'paragraph'),
    },
    leftButton: {
      label: 'Contact Customer Service',
      onClick: leftButtonOnClick,
      variant: AtButtonVariant.TERTIARY,
    },
    rightButton: {
      label: 'OK',
      onClick: rightButtonOnClick,
    },
  }
}
