import { Block, Text, Inline } from '@contentful/rich-text-types'
import { AtImageProps, MlRichTextProps, OrModalProductProps } from '@curran-catalog/curran-atomic-library'
import { FurnitureProductState } from 'context'
import {
  ContentfulObjFlooringSpecifications,
  ContentfulObjFlooringWeave,
  ContentfulObjFurnitureProduct,
  ContentfulObjFurnitureSpecifications,
  FlooringOrderButtonLabels,
} from 'types'
import { firstLetterCapitalize, formatPrice, getSpecsWeaveRichText, normalizeAsset } from 'utils'

const SPECIFICATIONS_CAPITALIZE_REGEX = /[A-Z][a-z]+|[0-9]+/g

export const getModalOrderInfo = (
  total: string,
  quantity: number,
  product: ContentfulObjFurnitureProduct,
  productState: FurnitureProductState,
  titles: {
    fabricTitle: string
    mainCushionsTitle: string
    addonsTitle: string
  },
) => {
  const infoProduct = {
    image: product.images
      ? (normalizeAsset({ asset: product.images[0] }) as AtImageProps)
      : ({ src: '/images/image-coming-soon.jpg', alt: '' } as AtImageProps),
    name: product.productName,
    price: total,
    quantity: quantity,
    extraInfo: normalizeExtraInfo(productState, product, titles),
  } as OrModalProductProps

  return infoProduct
}

export const normalizeExtraInfo = (
  productState: FurnitureProductState,
  product: ContentfulObjFurnitureProduct,
  titles: {
    fabricTitle: string
    mainCushionsTitle: string
    addonsTitle: string
  },
) => {
  const extraInfo = []
  productState.fabric && extraInfo.push({ title: titles.fabricTitle, text: productState.fabric?.fabricName })

  productState.presetFabricGrade &&
    extraInfo.push({
      title: titles.fabricTitle,
      text: productState?.presetFabricGrade?.fabricGradeName,
    })

  productState.primaryComboFrameSlingCol &&
    product.combos &&
    extraInfo.push({
      title: product.combos[0].comboName,
      text: productState.primaryComboFrameSlingCol?.frameSlingColName,
    })

  productState.secondaryComboFrameSlingCol &&
    product.combos &&
    extraInfo.push({
      title: product.combos[1].comboName,
      text: productState.secondaryComboFrameSlingCol?.frameSlingColName,
    })

  productState.tertiaryComboFrameSlingCol &&
    product.combos &&
    extraInfo.push({
      title: product.combos[2].comboName,
      text: productState.tertiaryComboFrameSlingCol?.frameSlingColName,
    })

  productState.quaternaryComboFrameSlingCol &&
    product.combos &&
    extraInfo.push({
      title: product.combos[3].comboName,
      text: productState.quaternaryComboFrameSlingCol?.frameSlingColName,
    })

  productState.mainCushionFabric &&
    extraInfo.push({ title: titles.mainCushionsTitle, text: productState.mainCushionFabric?.fabricName })

  productState.optionalCushionGroupFabrics?.forEach((optionalCushionGroupFabric) =>
    extraInfo.push({
      title: optionalCushionGroupFabric.cushionGroupName,
      text: `${optionalCushionGroupFabric.fabricName} x ${
        optionalCushionGroupFabric.hasQuantity && optionalCushionGroupFabric.quantity
          ? optionalCushionGroupFabric.quantity
          : productState.quantity
      }`,
    }),
  )

  productState.addonsFabricOrFrameSlingCol?.forEach((addon) =>
    extraInfo.push({
      title: addon.addonName,
      text: `${addon.quantity} x ${
        addon.price && addon.price >= 0 && addon.quantity ? formatPrice(addon.price / addon.quantity) : 0
      }`,
    }),
  )

  const addonGroup = productState.addonsGroupFabricOrFrameSlingCol
    ? Object.values(productState.addonsGroupFabricOrFrameSlingCol).reduce((acc, val) => acc.concat(val), [])
    : []

  addonGroup?.forEach((addon) =>
    extraInfo.push({
      title: addon.addonName,
      text: `${addon.quantity} x ${
        addon.price && addon.price >= 0 && addon.quantity ? formatPrice(addon.price / addon.quantity) : 0
      }`,
    }),
  )

  return extraInfo.length > 0 ? extraInfo : undefined
}

export const getSpecificationOptions = (
  specifications: ContentfulObjFurnitureSpecifications | ContentfulObjFlooringSpecifications | undefined,
): MlRichTextProps[] => {
  if (!specifications) return []

  const richTextSpecificationOptions = Object.entries(specifications)
    .map(([specFieldKey, specFieldValue]) => {
      // We only need to consider specification keys like width, length, height, etc
      if (
        !specFieldValue ||
        specFieldKey === 'CONTENTFUL_ID' ||
        specFieldKey === 'CONTENT_TYPE' ||
        specFieldKey === 'name' ||
        specFieldKey === 'UPDATED_AT' ||
        specFieldKey === '__typename' ||
        specFieldKey === 'sys'
      )
        return undefined

      const label = firstLetterCapitalize(specFieldKey)
        .split(/\s*Text\b/gi)[0]
        .match(SPECIFICATIONS_CAPITALIZE_REGEX)

      return {
        text: getSpecsWeaveRichText({ itemTitle: label ? label.join(' ') : specFieldKey, itemValue: specFieldValue }),
      }
    })
    .filter(Boolean)

  return richTextSpecificationOptions as MlRichTextProps[]
}

export const getSpecificationOptionsNodes = (
  specifications: ContentfulObjFurnitureSpecifications | ContentfulObjFlooringSpecifications | undefined,
) => {
  if (!specifications) return []

  const specs: (Block | Inline | Text)[] = []

  Object.entries(specifications).forEach(([specFieldKey, specFieldValue]) => {
    // We only need to consider specification keys like width, length, height, etc
    if (
      !specFieldValue ||
      specFieldKey === 'CONTENTFUL_ID' ||
      specFieldKey === 'CONTENT_TYPE' ||
      specFieldKey === 'name' ||
      specFieldKey === 'UPDATED_AT' ||
      specFieldKey === '__typename' ||
      specFieldKey === 'sys'
    )
      return undefined

    const label = firstLetterCapitalize(specFieldKey)
      .split(/\s*Text\b/gi)[0]
      .match(SPECIFICATIONS_CAPITALIZE_REGEX)

    specs.push(
      {
        nodeType: 'text',
        value: `${label ? label.join(' ') : specFieldKey}: `,
        marks: [
          {
            type: 'bold',
          },
        ],
        data: {},
      },
      {
        nodeType: 'text',
        value: `${specFieldValue}\n`,
        marks: [],
        data: {},
      },
    )
  })

  return specs
}

export const getWeaveSpecificationsNodes = (weave: ContentfulObjFlooringWeave) => {
  const specs: (Block | Inline | Text)[] = []

  const pushSpec = (itemTitle: string, itemValue: string) => {
    specs.push(
      {
        nodeType: 'text',
        value: `${itemTitle}: `,
        marks: [
          {
            type: 'bold',
          },
        ],
        data: {},
      },
      {
        nodeType: 'text',
        value: `${itemValue}\n`,
        marks: [],
        data: {},
      },
    )
  }

  const erpWeave: { [key: string]: string } = {}

  if (weave.erpWeaveAttributes) {
    weave.erpWeaveAttributes.forEach((weaveAttribute) => {
      if (Object.keys(weaveAttribute)[0]) {
        erpWeave[Object.keys(weaveAttribute)[0]] = Object.values(weaveAttribute)[0]
      }
    })
  }
  if (erpWeave.rollWidth) pushSpec('Roll Width', erpWeave.rollWidth)
  if (erpWeave.countryOfOrigin) pushSpec('Country of Origin', erpWeave.countryOfOrigin)
  if (erpWeave.usage) pushSpec('Usage', erpWeave.usage)
  if (erpWeave.backing) pushSpec('Backing', erpWeave.backing)
  if (erpWeave.fireRating) pushSpec('Fire Rating', erpWeave.fireRating)
  if (erpWeave.fiber) pushSpec('Fiber', erpWeave.fiber)
  if (erpWeave.cleanability) pushSpec('Cleanability', erpWeave.cleanability)
  if (erpWeave.bindingOptions) pushSpec('Binding Options', erpWeave.bindingOptions)
  if (erpWeave.weaveWeight) pushSpec('Weight', erpWeave.weaveWeight)
  weave.weaveStructureFamily && pushSpec('Method of Production', weave.weaveStructureFamily)
  return specs
}

export const getOrderButtonLabel = (product: ContentfulObjFlooringWeave) => {
  if (product.callForQuote) return FlooringOrderButtonLabels.CALL_FOR_QUOTE
  if (product.supportsRugBuilder) return FlooringOrderButtonLabels.RUG_BUILDER
  return FlooringOrderButtonLabels.DEFAULT
}
