import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FocusElement, focusElementClasses, MlRichText } from '@curran-catalog/curran-atomic-library'
import { Document } from '@contentful/rich-text-types'

const LIMIT_HEIGHT_DESCRIPTION = 120

interface TruncateDescriptionProps {
  description: Document
}

export const TruncateDescription = ({ description }: TruncateDescriptionProps) => {
  const descriptionRef = useRef<HTMLDivElement>(null)

  const [isReadMoreShown, setIsReadMoreShown] = useState<boolean>(false)
  const [heightDescription, setHeightDescription] = useState<number>(0)

  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    if (descriptionRef.current) {
      setHeightDescription(descriptionRef.current.clientHeight)
    }
  }, [descriptionRef])

  const handleReadMoreShown = useCallback(() => setIsReadMoreShown(!isReadMoreShown), [isReadMoreShown])

  return (
    <div className={`flex flex-col gap-1 ${!isReadMoreShown ? 'h-28 sm:h-[13rem] overflow-hidden' : ''}`}>
      {description && (
        <div ref={descriptionRef}>
          <MlRichText
            text={description}
            className={`${
              heightDescription > LIMIT_HEIGHT_DESCRIPTION && !isReadMoreShown ? 'clamp-3 sm:clamp-[7] [&>p]:block' : ''
            }`}
          />
        </div>
      )}

      {heightDescription > LIMIT_HEIGHT_DESCRIPTION && (
        <button
          className={`${focusElementClasses[FocusElement.FOCUS_VISIBLE]} w-fit underline text-secondary`}
          type="button"
          onClick={handleReadMoreShown}
        >
          {isReadMoreShown ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  )
}
