import { useEffect, useState } from 'react'

import { FurnitureProductActionTypes, ObjFabricState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ColorOptions, MlProductOptionsCollapse } from '@components/product-detail-options'
import {
  ContentfulObjFurnitureFabricGrade,
  ContentType,
  ErrorFurniturePrices,
  FABRIC_GRADES_DEFAULT_TITLE,
  FurniturePrices,
} from 'types'

interface FabricGradesProps {
  initialToggleCollapse: boolean
  fabricGradesTitle?: string
  fabricGrades: ContentfulObjFurnitureFabricGrade[]
  collapsePosition: string[]
  productPrices: FurniturePrices | ErrorFurniturePrices
}

export const FabricGrades = ({
  initialToggleCollapse,
  fabricGradesTitle,
  fabricGrades,
  collapsePosition,
  productPrices,
}: FabricGradesProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const { fabric } = state

  const [options, setOptions] = useState<ObjFabricState[]>([])

  useEffect(() => {
    const fabrics: ObjFabricState[] = []

    fabricGrades.forEach((fabricGrade) => {
      if (Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0) {
        const normalizeFabrics = fabricGrade.fabrics.map((fabric) => ({
          ...fabric,
          uniqueID: `${fabricGrade.CONTENTFUL_ID}-${fabric.CONTENTFUL_ID}`,
        }))
        fabrics.push(...normalizeFabrics)
      }

      const fabricPrices: ObjFabricState[] = fabrics.map((fabric) => {
        const fabricPrice = (productPrices as FurniturePrices).products.find(
          ({ grade }) => fabric.fabricGradeSku === grade,
        )
        return fabricPrice ? { ...fabric, price: fabricPrice?.price ?? 0, orderSku: fabricPrice?.sku ?? '' } : fabric
      })

      setOptions(fabricPrices)
    })
  }, [fabricGrades, productPrices])

  useEffect(() => {
    // Select the first option of fabric-grade collapse
    if (options.length > 0) {
      dispatch({
        type: FurnitureProductActionTypes.SET_FABRIC_GRADE,
        fabricGrade: options[0],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <MlProductOptionsCollapse
      index={collapsePosition.findIndex((option) => option === 'fabricGrades') + 1}
      isRequired={true}
      name={fabricGradesTitle ?? FABRIC_GRADES_DEFAULT_TITLE}
      optionSelected={fabric?.fabricName}
      initialToggleCollapse={initialToggleCollapse}
    >
      <ColorOptions contentType={ContentType.OBJ_FURNITURE_FABRIC_GRADE} options={options} />
    </MlProductOptionsCollapse>
  )
}
