import { SampleCartDTO } from '@services/cart'
import { siteNameStore, splitProductDescription } from '@utils/analytics/common'
import { getCurrentSynsisalRoute } from '@utils/get-synsisal-route'
import { CartItem } from '../../../types'
import { CartEventDTO, CartEventTypes } from '../../../types/analytics/analytics-data'
import { DetectSiteDomain } from '@utils/detect-site-domain'

/**
 * Transforms SampleCartDTO into CartEventDTO, the SampleCartDTO is only present on the order samples views.
 * outside the cart page
 * @param data
 * @param event
 * @param index
 * @param quantity
 * @param previousRoute
 * @param productName
 */
export const sampleCartToDTO = (
  data: SampleCartDTO,
  event: CartEventTypes,
  index?: number,
  quantity?: number,
  previousRoute?: string,
  productName?: string,
): CartEventDTO => {
  // Synsisal
  return {
    event: event,
    ecommerce: {
      currency: 'USD',
      items: [sampleCartItemToDTO(data, index, quantity, previousRoute, productName)],
    },
  }
}

export const sampleCartItemToDTO = (
  data: SampleCartDTO,
  index?: number,
  quantity?: number,
  previousRoute?: string,
  productName?: string,
) => {
  return {
    affiliation: siteNameStore,
    currency: 'USD',
    index,
    item_brand: 'synsisal',
    item_category: 'Custom Rugs samples',
    item_id: data.metadata?.contentful_id ?? '',
    item_list_name: previousRoute ?? getCurrentSynsisalRoute(),
    item_name: `${productName}: ${data.color} Sample`,
    item_stock_detail: 'In_stock',
    item_variant: data.color,
    price: 0,
    quantity: quantity,
  }
}

export const cartItemToDTO = (
  data: CartItem,
  event: CartEventTypes,
  siteDomainInfo: DetectSiteDomain,
  index?: number,
  quantity?: number,
  previousRoute?: string,
): CartEventDTO => {
  // Synsisal
  return {
    event: event,
    ecommerce: {
      currency: 'USD',
      ...(!siteDomainInfo.synsisalSite && { payment_type: 'credit card' }),
      items: [cartItemItemToDTO(data, siteDomainInfo, index, quantity, previousRoute)],
    },
  }
}

export const cartItemItemToDTO = (
  data: CartItem,
  siteDomainInfo: DetectSiteDomain,
  index?: number,
  quantity?: number,
  previousRoute?: string,
) => {
  const { synsisalSite, furnitureSite } = siteDomainInfo

  if (synsisalSite) {
    return {
      affiliation: siteNameStore,
      currency: 'USD',
      index,
      item_brand: 'synsisal',
      item_category: 'Custom Rugs samples',
      item_id: data.metadata?.contentful_id ?? '',
      item_list_name: previousRoute ?? getCurrentSynsisalRoute(),
      item_name: data.description,
      item_stock_detail: 'In_stock',
      item_variant: splitProductDescription(data.description)[1],
      price: 0,
      quantity: quantity,
    }
  }

  // trackAddToCart - Furniture
  if (furnitureSite) {
    return {
      affiliation: siteNameStore,
      currency: 'USD',
      ...(data.metadata?.index && { index: data.metadata?.index }),
      item_brand: data.metadata?.brand,
      item_category2: data.metadata?.category2 ?? '',
      item_category: data.metadata?.category,
      item_id: data.metadata?.contentful_id,
      item_list_name: data.metadata?.itemListName ?? document.title,
      item_name: data.description,
      item_variant: data.details ?? '',
      price: parseFloat(data.netPrice.replace('$', '').replace(',', '')),
      quantity: quantity || data.quantity,
    }
  }

  // trackAddToCart - Flooring
  return {
    affiliation: siteNameStore,
    currency: 'USD',
    ...(data.metadata?.index && { index: data.metadata?.index }),
    item_category: data.metadata?.category || '',
    item_category2: data.metadata?.category2 || '', // Choose rug type or carpet
    item_category3: data.metadata?.category3 || '', // choose border material
    item_category4: data.metadata?.category4 || '', // type of rug pad user choose
    item_category5: data.metadata?.category5 || '', // choose weave color
    item_id: data.metadata?.contentful_id,
    item_list_name: data.metadata?.itemListName ?? document.title,
    item_name: data.description.split(':').length > 1 ? data.description.split(':')[0] : data.description,
    item_stock_detail: 'In_stock', // stock of the product
    item_variant: data.metadata?.category5 || '', // color of the product
    price: data.netPrice
      ? parseFloat(data.netPrice.replace('$', '').replace(',', ''))
      : parseFloat(data.price.replace('$', '').replace(',', '')),
    quantity: data.quantity,
  }
}
