import { ChangeEvent, ChangeEventHandler } from 'react'
import { AtBaseInput, AtIcon, FocusElement, focusElementClasses } from '@curran-catalog/curran-atomic-library'

export enum QuantityVariant {
  NORMAL = 'normal',
  SMALL = 'small',
}

const quantityClassesVariant: Record<QuantityVariant, { size: string }> = {
  [QuantityVariant.NORMAL]: { size: 'w-10 h-10' },
  [QuantityVariant.SMALL]: { size: 'w-8 h-8 bg-subdued ring-0' },
}

export interface QuantityProps {
  value: number
  // Stock
  max: number
  min?: number
  variant?: QuantityVariant
  isTile?: boolean
  readOnly?: boolean
  helperText?: string
  increase: () => void
  decrease: () => void
  handleChange: ChangeEventHandler<HTMLInputElement>
}

export const Quantity = ({
  value,
  min = 1,
  max,
  variant = QuantityVariant.NORMAL,
  isTile = false,
  readOnly = false,
  helperText,
  increase,
  decrease,
  handleChange,
}: QuantityProps) => {
  const handlerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (Number(inputValue) < max + 1) handleChange(event)
  }

  const handlerBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue && Number(inputValue) < max + 1 && Number(inputValue) >= min) handleChange(event)
    else {
      event.target.value = min.toString()
      handleChange(event)
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center bg-subdued w-fit">
        {QuantityVariant.SMALL === variant && <span className="px-2">QTY:</span>}
        <button
          className={`flex justify-center items-center bg-subdued ${
            quantityClassesVariant[variant].size
          } disabled:cursor-not-allowed disabled:opacity-50 ${focusElementClasses[FocusElement.FOCUS_VISIBLE]}`}
          type="button"
          onClick={decrease}
          disabled={value <= min}
        >
          <AtIcon type="minus" size={16} color="secondary" />
        </button>
        <AtBaseInput
          inputClassName={`${quantityClassesVariant[variant].size} text-center w-full px-0`}
          onBlur={handlerBlur}
          onChange={handlerChange}
          value={value === 0 ? '' : value}
          min={min}
          max={max}
          readOnly={readOnly}
        />
        <button
          className={`flex justify-center items-center bg-subdued disabled:cursor-not-allowed disabled:opacity-50 ${
            quantityClassesVariant[variant].size
          } ${focusElementClasses[FocusElement.FOCUS_VISIBLE]}`}
          type="button"
          onClick={increase}
          disabled={value >= max || value < 0}
        >
          <AtIcon type="plus" size={16} color="secondary" />
        </button>
      </div>
      <span className="text-sm">
        {isTile && `Minimum of ${min} boxes. `}
        {helperText && helperText}
      </span>
    </div>
  )
}
