import React, { memo, useEffect, useMemo } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ContentfulObjFurnitureFabricGrade } from 'types'

interface RadioPresetFabricOptionProps {
  fabricGrade: ContentfulObjFurnitureFabricGrade
}

const RadioPresetFabricOption = memo(({ fabricGrade }: RadioPresetFabricOptionProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const { presetFabricGrade, productsDisabled } = state

  const slashedFabrics = useMemo(
    () =>
      Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0
        ? fabricGrade.fabrics.map((fabric) => fabric.CONTENTFUL_ID).join('/')
        : '',
    [fabricGrade.fabrics],
  )

  const hasSelectedOption = useMemo(
    () =>
      presetFabricGrade && presetFabricGrade.fabrics
        ? presetFabricGrade.fabrics.map((fabric) => fabric.CONTENTFUL_ID).join('/') === slashedFabrics
        : false,
    [presetFabricGrade, slashedFabrics],
  )

  return (
    <>
      {Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0 ? (
        <li
          onClick={() =>
            dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
          }
          className={`p-2 border ${hasSelectedOption ? 'border-secondary' : ' border-outlined-gray'}`}
        >
          <MlFormField
            label={fabricGrade.fabrics.map((fabric) => fabric.fabricName).join('/')}
            name={`radio-preset-fabric-${fabricGrade.CONTENTFUL_ID}-${slashedFabrics}`}
            onChange={() =>
              dispatch({
                type: FurnitureProductActionTypes.SET_HAS_PRESET_FABRICS,
                presetFabricGrade: fabricGrade,
              })
            }
            disabled={productsDisabled}
            type="radio"
            value={undefined}
            checked={hasSelectedOption}
          />
        </li>
      ) : null}
    </>
  )
})

interface RadioPresetFabricOptionsProps {
  fabricGrades: ContentfulObjFurnitureFabricGrade[]
}

export const RadioPresetFabricOptions = ({ fabricGrades }: RadioPresetFabricOptionsProps) => {
  const { dispatch } = useFurnitureProduct()

  useEffect(() => {
    // Select the first option of preset-fabric collapse
    if (Array.isArray(fabricGrades) && fabricGrades.length > 0) {
      const foundFabricGrade = fabricGrades.find(
        (fabricGrade) => Array.isArray(fabricGrade.fabrics) && fabricGrade.fabrics.length > 0,
      )
      if (foundFabricGrade) {
        dispatch({
          type: FurnitureProductActionTypes.SET_HAS_PRESET_FABRICS,
          presetFabricGrade: foundFabricGrade,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fabricGrades])

  return (
    <ul className="flex flex-col gap-4 flex-wrap px-4 mb-5">
      {Array.isArray(fabricGrades) && fabricGrades.length > 0
        ? fabricGrades.map((fabricGrade) => (
            <RadioPresetFabricOption key={fabricGrade.CONTENTFUL_ID} fabricGrade={fabricGrade} />
          ))
        : null}
    </ul>
  )
}
