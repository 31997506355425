import { memo, useEffect, useMemo } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes, ObjFrameSlingColState } from 'context'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ComboLength } from '@components/product-detail-collapses'
import { formatPrice, handleComboHierarchy, highlightOption, normalizeAsset } from 'utils'
import { isComboFrameSlingColDisabled } from './combo-color-options'

interface RadioOptionProps {
  hierarchy: ComboLength
  option: ObjFrameSlingColState
  hasSelectedOption: boolean
  useGroupedOptions?: boolean
}

const RadioOption = memo(({ hierarchy, option, hasSelectedOption, useGroupedOptions }: RadioOptionProps) => {
  const { CONTENTFUL_ID, frameSlingColName, price, images } = option

  const { state, dispatch } = useFurnitureProduct()
  const { primaryComboFrameSlingCol, secondaryComboFrameSlingCol, tertiaryComboFrameSlingCol, productsDisabled } = state

  const isOptionDisabled = useMemo(
    () =>
      productsDisabled
        ? true
        : !useGroupedOptions && hierarchy >= ComboLength.SECONDARY
        ? !isComboFrameSlingColDisabled({
            hierarchy,
            parentPrimaryId: option?.parentPrimaryId,
            primaryComboFrameSlingColId: primaryComboFrameSlingCol?.CONTENTFUL_ID,
            parentSecondaryId: option?.parentSecondaryId,
            secondaryComboFrameSlingColId: secondaryComboFrameSlingCol?.CONTENTFUL_ID,
            parentTertiaryId: option?.parentTertiaryId,
            tertiaryComboFrameSlingColId: tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
          })
        : false,
    [
      productsDisabled,
      useGroupedOptions,
      hierarchy,
      option?.parentPrimaryId,
      option?.parentSecondaryId,
      option?.parentTertiaryId,
      primaryComboFrameSlingCol?.CONTENTFUL_ID,
      secondaryComboFrameSlingCol?.CONTENTFUL_ID,
      tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
    ],
  )
  return (
    <li
      className={`p-2 border ${hasSelectedOption ? 'border-secondary' : ' border-outlined-gray'} ${
        isOptionDisabled ? 'opacity-50' : ''
      }`}
      onClick={() =>
        dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
      }
    >
      <MlFormField
        fieldId={`radio-combo-${CONTENTFUL_ID}`}
        label={`${frameSlingColName} ${price ? `(+${formatPrice(price)})` : ''}`}
        name={`radio-combo-${CONTENTFUL_ID}`}
        disabled={isOptionDisabled}
        onChange={() => {
          handleComboHierarchy(
            hierarchy,
            () => {
              dispatch({
                type: FurnitureProductActionTypes.SET_PRIMARY_COMBO,
                primaryComboFrameSlingCol: option,
              })
              if (!useGroupedOptions && primaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                dispatch({
                  type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
                  secondaryComboFrameSlingCol: undefined,
                })
                dispatch({
                  type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                  tertiaryComboFrameSlingCol: undefined,
                })
                dispatch({
                  type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                  quaternaryComboFrameSlingCol: undefined,
                })
              }
            },
            () => {
              dispatch({
                type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
                secondaryComboFrameSlingCol: option,
              })
              if (!useGroupedOptions && secondaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                dispatch({
                  type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                  tertiaryComboFrameSlingCol: undefined,
                })
                dispatch({
                  type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                  quaternaryComboFrameSlingCol: undefined,
                })
              }
            },
            () => {
              dispatch({
                type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                tertiaryComboFrameSlingCol: option,
              })
              if (!useGroupedOptions && tertiaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                dispatch({
                  type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                  quaternaryComboFrameSlingCol: undefined,
                })
              }
            },
            () =>
              dispatch({
                type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                quaternaryComboFrameSlingCol: option,
              }),
          )

          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        }}
        type="radio"
        value={price}
        image={Array.isArray(images) && images.length > 0 ? normalizeAsset({ asset: images[0] }) : undefined}
        checked={!!hasSelectedOption}
      />
      {option.subHeader && <p className="text-xs gap-1">{option.subHeader}</p>}
    </li>
  )
})

interface RadioOptionsProps {
  hierarchy: number
  options: ObjFrameSlingColState[]
  useGroupedOptions?: boolean
}

export const RadioOptions = memo(({ hierarchy, options = [], useGroupedOptions }: RadioOptionsProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const {
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
  } = state

  useEffect(() => {
    if (hierarchy === ComboLength.PRIMARY) {
      dispatch({
        type: FurnitureProductActionTypes.SET_PRIMARY_COMBO,
        primaryComboFrameSlingCol: options[0],
      })
      dispatch({
        type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchy])

  useEffect(() => {
    if (!useGroupedOptions && hierarchy === ComboLength.SECONDARY) {
      if (primaryComboFrameSlingCol?.CONTENTFUL_ID) {
        const filteredOptions = options.filter(
          (filterOption) => filterOption?.parentPrimaryId === primaryComboFrameSlingCol?.CONTENTFUL_ID,
        )

        dispatch({
          type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
          secondaryComboFrameSlingCol: filteredOptions[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useGroupedOptions, hierarchy, primaryComboFrameSlingCol?.CONTENTFUL_ID])

  useEffect(() => {
    if (!useGroupedOptions && hierarchy === ComboLength.TERTIARY) {
      if (primaryComboFrameSlingCol?.CONTENTFUL_ID && secondaryComboFrameSlingCol?.CONTENTFUL_ID) {
        const filteredOptions = options.filter(
          (filterOption) => filterOption?.parentSecondaryId === secondaryComboFrameSlingCol?.CONTENTFUL_ID,
        )

        dispatch({
          type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
          tertiaryComboFrameSlingCol: filteredOptions[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useGroupedOptions,
    primaryComboFrameSlingCol?.CONTENTFUL_ID,
    secondaryComboFrameSlingCol?.CONTENTFUL_ID,
    hierarchy,
  ])

  useEffect(() => {
    if (!useGroupedOptions && hierarchy === ComboLength.QUATERNARY) {
      if (
        primaryComboFrameSlingCol?.CONTENTFUL_ID &&
        secondaryComboFrameSlingCol?.CONTENTFUL_ID &&
        tertiaryComboFrameSlingCol?.CONTENTFUL_ID
      ) {
        const filteredOptions = options.filter(
          (filterOption) => filterOption?.parentTertiaryId === tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
        )

        dispatch({
          type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
          quaternaryComboFrameSlingCol: filteredOptions[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useGroupedOptions,
    primaryComboFrameSlingCol?.CONTENTFUL_ID,
    secondaryComboFrameSlingCol?.CONTENTFUL_ID,
    tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
    hierarchy,
  ])

  return (
    <ul className="flex flex-col gap-4 flex-wrap px-4 mb-5">
      {options.map((option) => {
        const { CONTENTFUL_ID } = option
        const hasSelectedOption = highlightOption(
          hierarchy,
          CONTENTFUL_ID,
          primaryComboFrameSlingCol?.CONTENTFUL_ID,
          secondaryComboFrameSlingCol?.CONTENTFUL_ID,
          tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
          quaternaryComboFrameSlingCol?.CONTENTFUL_ID,
        )

        return (
          <RadioOption
            key={`combo-radio-${CONTENTFUL_ID}`}
            hierarchy={hierarchy}
            option={option}
            hasSelectedOption={hasSelectedOption}
            useGroupedOptions={useGroupedOptions}
          />
        )
      })}
    </ul>
  )
})
