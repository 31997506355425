import { ContentfulObjAddonGroup, ContentfulObjFurnitureCombo, ContentfulObjFurnitureOptionalCushionGroup } from 'types'

export type ProductProperties = {
  hasPresetFabrics?: boolean
  hasFabricGrades: boolean
  hasCombos: boolean
  hasMainCushions: boolean
  hasOptionalCushionsGroup: boolean
  hasAddons: boolean
  hasAddonsGroup: boolean
  combos?: ContentfulObjFurnitureCombo[]
  optionalCushionsGroup?: ContentfulObjFurnitureOptionalCushionGroup[]
  addonGroups?: ContentfulObjAddonGroup[]
}
export const getPosition = (props: ProductProperties) => {
  const {
    hasPresetFabrics,
    hasFabricGrades,
    hasCombos,
    combos,
    hasOptionalCushionsGroup,
    optionalCushionsGroup,
    hasMainCushions,
    hasAddons,
    hasAddonsGroup,
    addonGroups,
  } = props

  const options: {
    [x: string]: boolean
  } = {}

  if (hasCombos) {
    combos?.forEach((_, index) => (options[`combo${index + 1}`] = true))
  }

  if (hasPresetFabrics && hasFabricGrades) {
    options.presetFabrics = true
  }

  if (!hasPresetFabrics && hasFabricGrades) {
    options.fabricGrades = true
  }

  if (hasMainCushions) {
    options.mainCushions = true
  }

  if (hasOptionalCushionsGroup) {
    optionalCushionsGroup?.forEach((_, index) => (options[`optional-cushion-group${index + 1}`] = true))
  }

  if (hasAddons) {
    options.addons = true
  }

  if (hasAddonsGroup) {
    addonGroups?.forEach((_, index) => (options[`addons-group${index + 1}`] = true))
  }

  return Object.keys(options)
}
