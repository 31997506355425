import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { Quantity, QuantityVariant } from '@components/product-detail-options'
import { FurnitureProductActionTypes, ObjAddonState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { normalizeAsset } from '@utils/normalize'
import { formatPrice } from '@utils/price'
import { ContentfulObjAddonGroup, FurniturePrices } from 'types'

interface CheckboxOptionProps {
  index: number
  option: ObjAddonState
  hasQuantity: boolean
  isChecked?: boolean
  showImage: boolean
  handleInputChange: (price: number, quantity: number, option: ObjAddonState, orderSku?: string) => void
  handleButtonQuantityChange: (price: number, quantity: number, option: ObjAddonState) => void
}

const RadioAddonOption = memo(
  ({
    index,
    isChecked = false,
    option,
    showImage,
    hasQuantity,
    handleInputChange,
    handleButtonQuantityChange,
  }: CheckboxOptionProps) => {
    const { CONTENTFUL_ID, addonPrices, sku, addonName, combos, fabricGrades, uniqueID, images } = option

    const hasFabricGrades = Array.isArray(fabricGrades) && fabricGrades.length > 0
    const hasCombos = Array.isArray(combos) && combos.length > 0

    const { state, dispatch } = useFurnitureProduct()
    const { addonsGroupFabricOrFrameSlingCol, productsDisabled } = state

    const [quantity, setQuantity] = useState<number>(1)
    const [isQuantityUpdated, setQuantityUpdated] = useState(false)

    const getAddonPrice = useCallback(() => {
      if (
        addonPrices &&
        Array.isArray((addonPrices as FurniturePrices).products) &&
        (addonPrices as FurniturePrices).products.length > 0
      ) {
        if (!hasFabricGrades && !hasCombos) {
          // Return the first variation
          return (addonPrices as FurniturePrices).products[0]
        }
        if (!hasFabricGrades && hasCombos) {
          const addonPriceFounded = (addonPrices as FurniturePrices).products.find(({ option1 }) => {
            return combos.find((combo) =>
              combo?.items?.find((item) => {
                return item.CONTENTFUL_ID === option1
              }),
            )
          })

          return addonPriceFounded ?? undefined
        }

        if (hasFabricGrades && !hasCombos) {
          const addonPriceFounded = (addonPrices as FurniturePrices).products.find(({ grade }) => {
            return fabricGrades.find((fabricGrade) =>
              fabricGrade?.fabrics?.find((_) => {
                return fabricGrade.sku === grade
              }),
            )
          })

          return addonPriceFounded ?? undefined
        }
      }
      return undefined
    }, [addonPrices, combos, fabricGrades, hasCombos, hasFabricGrades])

    // Reset the quantity when clear-options btn is clicked
    useEffect(() => {
      if (addonsGroupFabricOrFrameSlingCol === undefined) {
        setQuantity(1)
      }
    }, [addonsGroupFabricOrFrameSlingCol, productsDisabled])

    useEffect(() => {
      if (isQuantityUpdated) {
        handleButtonQuantityChange((getAddonPrice()?.price ?? 0) * quantity, quantity, option)

        setQuantityUpdated(false)
      }
    }, [
      isQuantityUpdated,
      handleButtonQuantityChange,
      index,
      quantity,
      hasFabricGrades,
      hasCombos,
      addonPrices,
      getAddonPrice,
      option,
      productsDisabled,
    ])

    const handleQuantityChange = useCallback((value = 1) => {
      setQuantity((prevState) => prevState + value)
      setQuantityUpdated(true)
    }, [])

    return (
      <li
        className={`flex flex-col gap-x-2 p-2 border ${isChecked ? 'border-secondary' : ' border-outlined-gray'}`}
        onClick={() =>
          dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
        }
      >
        <MlFormField
          disabled={!!productsDisabled}
          fieldId={uniqueID ? `${uniqueID}-${CONTENTFUL_ID}` : CONTENTFUL_ID}
          label={`${addonName}
          ${
            hasFabricGrades
              ? `/ ${
                  fabricGrades.find((fabricGrade) =>
                    fabricGrade?.fabrics?.find((fabric) => fabric?.sku === getAddonPrice()?.grade),
                  )?.fabricGradeName
                }`
              : ''
          }
           ${
             hasCombos
               ? `/ ${
                   combos.find((combo) => combo?.items?.find((item) => item?.sku === getAddonPrice()?.option1))
                     ?.comboName
                 }`
               : ''
           } ${getAddonPrice()?.price ? `(+${formatPrice((getAddonPrice()?.price ?? 0) * quantity)})` : ''} `}
          name={uniqueID ? `${uniqueID}-${CONTENTFUL_ID}` : CONTENTFUL_ID}
          type="radio"
          value={sku}
          checked={isChecked}
          image={
            Array.isArray(images) && images.length > 0 && showImage ? normalizeAsset({ asset: images[0] }) : undefined
          }
          onChange={() => {
            handleInputChange((getAddonPrice()?.price ?? 0) * quantity, quantity, option, getAddonPrice()?.sku)
          }}
        />
        {isChecked && hasQuantity && (
          <div className={Array.isArray(images) && images.length > 0 && showImage ? 'ml-24' : 'ml-6'}>
            <Quantity
              variant={QuantityVariant.SMALL}
              min={1}
              max={99}
              value={quantity}
              handleChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                setQuantity(Number(value))
                setQuantityUpdated(true)
              }}
              increase={() => handleQuantityChange(1)}
              decrease={() => handleQuantityChange(-1)}
            />
          </div>
        )}
      </li>
    )
  },
)

interface RadioAddonOptionsProps {
  addonGroupId?: ContentfulObjAddonGroup['CONTENTFUL_ID']
  options: ObjAddonState[]
  showImage?: boolean
  hasQuantity?: boolean
  handleContextAction: (updatedState: ObjAddonState[]) => void
}

export const RadioAddonOptions = ({
  hasQuantity = true,
  addonGroupId,
  options,
  showImage = false,
  handleContextAction,
}: RadioAddonOptionsProps) => {
  const { state } = useFurnitureProduct()
  const { addonsGroupFabricOrFrameSlingCol } = state

  const handleInputChange = useCallback(
    (price: number, quantity: number, option: ObjAddonState, orderSku?: string) => {
      handleContextAction([
        {
          ...option,
          price,
          quantity,
          orderSku,
        },
      ])
    },

    [handleContextAction],
  )

  const handleButtonQuantityChange = useCallback(
    (price: number, quantity: number, option: ObjAddonState) => {
      handleContextAction([{ ...option, price, quantity }])
    },

    [handleContextAction],
  )

  const hasAddonsGroup =
    addonsGroupFabricOrFrameSlingCol &&
    addonGroupId &&
    Array.isArray(addonsGroupFabricOrFrameSlingCol[addonGroupId]) &&
    addonsGroupFabricOrFrameSlingCol[addonGroupId].length > 0

  return (
    <ul className="flex flex-col gap-4 flex-wrap px-4 my-5 bg-white">
      {options.map((option, index) => {
        const { CONTENTFUL_ID } = option

        return (
          <RadioAddonOption
            key={`radio-list-${index}-${addonGroupId ? `${addonGroupId}-${CONTENTFUL_ID}` : CONTENTFUL_ID}`}
            index={index}
            option={addonGroupId ? { ...option, uniqueID: `${addonGroupId}-${CONTENTFUL_ID}` } : option}
            isChecked={
              hasAddonsGroup
                ? addonsGroupFabricOrFrameSlingCol[addonGroupId][0]?.uniqueID === `${addonGroupId}-${CONTENTFUL_ID}`
                : false
            }
            showImage={showImage}
            hasQuantity={hasQuantity}
            handleInputChange={handleInputChange}
            handleButtonQuantityChange={handleButtonQuantityChange}
          />
        )
      })}
    </ul>
  )
}
