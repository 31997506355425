import { TruncateDescription } from '@components/common-product-detail'
import { formatPrice } from '@utils/price'
import { ContentfulObjFlooringWeave } from 'types'
import { ColorSlider } from './color-slider'
import { AtIcon, MlPopover } from '@curran-catalog/curran-atomic-library'
import { DEFAULT_INFO_ICON_COPY } from '@utils/common'
import { memo, useMemo } from 'react'
import { sortFn } from 'color-sorter'

interface ProductDescriptionProps {
  product: ContentfulObjFlooringWeave
  supportRugBuilder?: boolean
  callForQuote?: boolean
  isTile?: boolean
  isWallToWall?: boolean
  isWallCovering?: boolean
  areaRugStartingAtPrice?: number
  startingAtPrice?: number
  className?: string
  showColorPicker?: boolean
}

export const ProductDescription = ({
  product,
  supportRugBuilder = false,
  callForQuote = false,
  isTile = false,
  isWallToWall = false,
  isWallCovering = false,
  areaRugStartingAtPrice,
  startingAtPrice,
  className,
  showColorPicker = false,
}: ProductDescriptionProps) => {
  const rollWidth = useMemo(() => {
    const erpWeave: { [key: string]: string } = {}

    if (product.erpWeaveAttributes) {
      product.erpWeaveAttributes.forEach((weaveAttribute) => {
        if (Object.keys(weaveAttribute)[0]) {
          erpWeave[Object.keys(weaveAttribute)[0]] = Object.values(weaveAttribute)[0]
        }
      })
    }

    return erpWeave.rollWidth ? erpWeave.rollWidth : null
  }, [product.erpWeaveAttributes])

  return (
    <div className={className}>
      {/* Title */}
      <div>
        <span className="text-3xl">{product.weaveName}</span>
        {!isTile && (!isWallToWall || (isWallToWall && supportRugBuilder)) && (
          <div className="mt-1">{rollWidth && <span>Max. Width: {rollWidth}</span>}</div>
        )}
      </div>

      {/* Price */}
      {callForQuote ? (
        <p className="text-base">Request Quote</p>
      ) : (
        <>
          {!isTile &&
            !isWallCovering &&
            (!isWallToWall || (isWallToWall && supportRugBuilder)) &&
            !!areaRugStartingAtPrice && (
              <div className="flex">
                <p className="text-base">
                  Area rugs starting at:{' '}
                  <span className="font-medium text-base text-dark">{formatPrice(areaRugStartingAtPrice)}</span>
                </p>
                {!!areaRugStartingAtPrice && !!startingAtPrice && areaRugStartingAtPrice !== startingAtPrice && (
                  <MlPopover description={product.infoIconCopy ?? DEFAULT_INFO_ICON_COPY}>
                    <AtIcon type="info" color="link" className="mx-3" />
                  </MlPopover>
                )}
              </div>
            )}
          {startingAtPrice && (
            <p className="text-base">
              {isTile || isWallToWall || isWallCovering ? '' : 'Wall-to-wall:'}{' '}
              <span className="font-medium text-base text-dark">
                {formatPrice(startingAtPrice)} {isTile ? '/ Box' : '/ sqyd'}
              </span>
            </p>
          )}
        </>
      )}

      <div className="flex flex-col-reverse md:flex-col gap-4 md:gap-0 ">
        {/* Weave Color Slider */}
        {showColorPicker && product.colorGroup && (
          <div className="md:py-2">
            <ColorSlider
              weaveColors={
                product?.colorGroup?.weaveColors?.sort((a, b) =>
                  sortFn(a.colorHex ?? '#000000', b.colorHex ?? '#000000'),
                ) ?? []
              }
            />
          </div>
        )}

        {/* Description */}
        {product.description && <TruncateDescription description={product.description} />}
      </div>
    </div>
  )
}

export default memo(ProductDescription)
