import { Fragment, ReactNode } from 'react'
import { Popover, Transition } from '@headlessui/react'

interface PopoverWrapperProps {
  children: ReactNode
  openModal: boolean
}

export const PopoverWrapper = ({ openModal, children }: PopoverWrapperProps) => {
  return (
    <Transition show={openModal} as={Fragment}>
      <Popover className="relative w-full container z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-250"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed top-[40%] left-1/3 2xl:left-[38%]">
            <Popover.Panel
              className="border-outlined-gray border w-full max-w-xs h-fit transform overflow-hidden bg-white text-left align-middle transition-all"
              static
            >
              <div className="p-2 md:p-4 flex flex-col gap-4">{children}</div>
            </Popover.Panel>
          </div>
        </Transition.Child>
      </Popover>
    </Transition>
  )
}
