import { AtLink } from '@curran-catalog/curran-atomic-library'
import { TruncateDescription } from '../common-product-detail/truncate-description'
import { ContentfulObjFurnitureProduct } from 'types'
import { formatPrice } from '@utils/price'
import { memo } from 'react'

interface ProductDescriptionProps {
  product: ContentfulObjFurnitureProduct
  startingPrice: number
  cushionMinPrice: number
  className?: string
}

export const ProductDescription = ({
  product,
  startingPrice,
  cushionMinPrice,
  className = '',
}: ProductDescriptionProps) => {
  const { productName, vendor, description } = product
  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      {/* Title */}
      <div>
        <span className="text-xl text-secondary xl:text-3xl">{productName}</span>
        <div className="mt-1">
          {vendor && vendor.vendorName && vendor?.vendorNameSlug && (
            <AtLink
              label={`by ${vendor.vendorName}`}
              actionUrl={`/catalog/${vendor.vendorNameSlug}`}
              className="w-fit text-secondary text-sm"
            />
          )}
        </div>
      </div>

      {/* Price */}
      <p className="text-base">
        {product.callForQuote ? (
          <span>Request Quote</span>
        ) : (
          <>
            Starting at{' '}
            <span className="font-medium text-base text-dark">{formatPrice(startingPrice + cushionMinPrice)}</span>
          </>
        )}
      </p>

      {/* Description */}
      {description && <TruncateDescription description={description} />}
    </div>
  )
}

export default memo(ProductDescription)
