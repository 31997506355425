import { useCallback } from 'react'

import { RugBuilderActionTypes, PreviewConfirmKeys } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'

export type ValidatePreviewStepsProps = {
  steps: PreviewConfirmKeys[]
  currentStep: PreviewConfirmKeys
  nextStep: PreviewConfirmKeys
}

export const useValidatePreviewSteps = ({ steps, currentStep, nextStep }: ValidatePreviewStepsProps) => {
  const { state, dispatch } = useRugBuilder()
  const { previewConfirm } = state

  const handleCollapse = useCallback(() => {
    steps.forEach((step) => {
      if (previewConfirm[step]) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
          keyPreviewConfirmPayload: step,
          valuePreviewConfirmPayload: false,
        })
      }
    })

    dispatch({
      type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
      keyPreviewConfirmPayload: currentStep,
      valuePreviewConfirmPayload: !previewConfirm[currentStep],
    })
  }, [currentStep, dispatch, previewConfirm, steps])

  const openNextCollapse = useCallback(
    () =>
      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
        keyPreviewConfirmPayload: nextStep,
        valuePreviewConfirmPayload: true,
      }),
    [nextStep, dispatch],
  )

  return { isOpenCollapse: previewConfirm[currentStep], handleCollapse, openNextCollapse }
}
