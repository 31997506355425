import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react'
import { MlSwatchCard } from '@curran-catalog/curran-atomic-library'

import { ObjFabricState, FurnitureProductActionTypes } from '@context/furniture-product'
import { ContentOptionPopover, OptionPopover } from './option-popover'
import { useMediaQuery } from '@hooks/use-media-query'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { formatPrice } from '@utils/price'
import { isValidUrl } from '@utils/url'
import { normalizeAsset } from '@utils/normalize'
import { ContentfulObjFurnitureOptionalCushionGroup } from 'types'
import { PopoverWrapper } from '@components/PopoverWrapper'

interface ColorOptionalCushionGroupOptionProps {
  fabric: ObjFabricState
  hasQuantity: ContentfulObjFurnitureOptionalCushionGroup['hasQuantity']
  onOpenPopover: () => void
  onClosePopover: () => void
}

export const OptionalCushionGroupColorOptions = memo(
  ({ fabric, hasQuantity = false, onOpenPopover, onClosePopover }: ColorOptionalCushionGroupOptionProps) => {
    const { state, dispatch } = useFurnitureProduct()
    const { optionalCushionGroupFabrics, productsDisabled } = state

    const { images, uniqueID, fabricName, price, isPopover = false, description, CONTENTFUL_ID } = fabric

    const isDesktop = useMediaQuery('(min-width: 1280px)')

    const shouldValidateQuantity = useRef(false)

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [quantity, setQuantity] = useState<number>(1)

    const hasSelectedOption = optionalCushionGroupFabrics
      ? !!optionalCushionGroupFabrics.find((optionalCushion) => optionalCushion.uniqueID === fabric.uniqueID)
      : false

    const handleOptionalCushionsGroup = useCallback((): ObjFabricState[] => {
      if (Array.isArray(optionalCushionGroupFabrics) && optionalCushionGroupFabrics.length > 0) {
        const foundCushionsGroupFabric = optionalCushionGroupFabrics.find(
          (optionalCushionGroup) => optionalCushionGroup.cushionGroupId === fabric.cushionGroupId,
        )
        return foundCushionsGroupFabric
          ? optionalCushionGroupFabrics.map((optionalCushionGroup) =>
              optionalCushionGroup.cushionGroupId === fabric.cushionGroupId
                ? { ...fabric, quantity, hasQuantity }
                : optionalCushionGroup,
            )
          : [...optionalCushionGroupFabrics, { ...fabric, quantity, hasQuantity }]
      }

      return [{ ...fabric, quantity, hasQuantity }]
    }, [fabric, optionalCushionGroupFabrics, quantity, hasQuantity])

    const hasImages = Array.isArray(images) && images.length > 0

    const handleQuantityChange = useCallback((value = 1) => {
      setQuantity((prevState) => prevState + value)
    }, [])

    useEffect(() => {
      if (shouldValidateQuantity.current) {
        dispatch({
          type: FurnitureProductActionTypes.SET_OPTIONAL_CUSHIONS_GROUP,
          optionalCushionGroupFabrics: handleOptionalCushionsGroup(),
        })

        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })

        shouldValidateQuantity.current = false
      }
    }, [dispatch, handleOptionalCushionsGroup])

    return (
      <li
        className={`w-fit justify-self-center h-full ${isPopover ? 'col-span-2' : ''}`}
        onClick={() =>
          dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
        }
      >
        {isPopover ? (
          <OptionPopover onClose={() => onClosePopover()}>
            <ContentOptionPopover
              price={price ? formatPrice(Number(price * quantity)) : ''}
              name={fabricName}
              description={description}
              images={images}
            />
          </OptionPopover>
        ) : (
          <div onMouseEnter={() => setIsOpenModal(true)} onMouseLeave={() => setIsOpenModal(false)} className="h-full">
            <MlSwatchCard
              disabled={!!productsDisabled}
              id={uniqueID ?? CONTENTFUL_ID}
              selected={hasSelectedOption}
              title={`${fabricName} ${price ? `(+${formatPrice(Number(price * quantity))})` : ''}`}
              description={fabric.subHeader ?? ''}
              image={
                hasImages && isValidUrl(images[0].secure_url)
                  ? normalizeAsset({ asset: images[0] })
                  : { src: '/images/image-coming-soon.jpg', alt: '' }
              }
              clickHandler={() => {
                dispatch({
                  type: FurnitureProductActionTypes.SET_OPTIONAL_CUSHIONS_GROUP,
                  optionalCushionGroupFabrics: handleOptionalCushionsGroup(),
                })

                dispatch({
                  type: FurnitureProductActionTypes.SET_OPTIONAL_CUSHION_GROUP_PRICE_NOT_FOUND,
                })

                dispatch({
                  type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
                })

                onOpenPopover()
              }}
              quantity={{
                min: 1,
                value: quantity,
                showQuantity: hasQuantity,
                onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                  setQuantity(Number(value))
                  shouldValidateQuantity.current = true
                },
                increase: () => {
                  handleQuantityChange(1)
                  shouldValidateQuantity.current = true
                },
                decrease: () => {
                  handleQuantityChange(-1)
                  shouldValidateQuantity.current = true
                },
              }}
            />

            {isDesktop && (
              <PopoverWrapper openModal={isOpenModal}>
                <ContentOptionPopover
                  price={price ? formatPrice(Number(price * quantity)) : ''}
                  name={fabricName}
                  description={description}
                  images={images}
                />
              </PopoverWrapper>
            )}
          </div>
        )}
      </li>
    )
  },
)
