import { useCallback, useEffect } from 'react'

import { CheckboxOptions, MlProductOptionsCollapse } from '@components/product-detail-options'
import { FurnitureProductActionTypes, ObjAddonState } from '@context/furniture-product'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { AddonsGroupVariant, ContentfulObjAddonGroup } from 'types'
import { RadioAddonOptions } from './radio-addon-options'

const ADDONS_GROUP_DEFAULT_TITLE = 'Addons Group'

interface AddonsGroupProps {
  initialToggleCollapse: boolean
  addonGroup: ContentfulObjAddonGroup
  position: number
  collapsePosition: string[]
}

export const AddonsGroup = ({ initialToggleCollapse, collapsePosition, addonGroup, position }: AddonsGroupProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const { addonsGroupFabricOrFrameSlingCol } = state

  const handleCheckboxOption = useCallback(
    (updatedState: ObjAddonState[]) => {
      dispatch({
        type: FurnitureProductActionTypes.SET_ADDONS_GROUP,
        addonGroupId: addonGroup.CONTENTFUL_ID,
        addons: updatedState,
      })

      dispatch({
        type: FurnitureProductActionTypes.SET_ADDONS_GROUP_PRICE_NOT_FOUND,
      })

      dispatch({
        type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
      })
    },
    [addonGroup.CONTENTFUL_ID, dispatch],
  )

  useEffect(() => {
    dispatch({
      type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addonsGroupFabricOrFrameSlingCol])

  return (
    <MlProductOptionsCollapse
      initialToggleCollapse={position === 0 && initialToggleCollapse}
      index={collapsePosition.findIndex((option) => option === `addons-group${position + 1}`) + 1}
      isRequired={addonGroup.required ?? false}
      name={addonGroup.addonGroupName ?? `${ADDONS_GROUP_DEFAULT_TITLE} ${position + 1}`}
      isMultiValue
      optionSelected={
        addonGroup?.addons?.find((addon) =>
          addonsGroupFabricOrFrameSlingCol && Object.keys(addonsGroupFabricOrFrameSlingCol).length > 0
            ? addonsGroupFabricOrFrameSlingCol[addonGroup.CONTENTFUL_ID]?.find(
                (selectedAddon) => selectedAddon.CONTENTFUL_ID === addon.CONTENTFUL_ID,
              )
            : undefined,
        )?.addonName
      }
    >
      {addonGroup.variant === AddonsGroupVariant.CHECKBOX ? (
        <CheckboxOptions
          addonGroupId={addonGroup.CONTENTFUL_ID}
          options={addonGroup.addons ?? []}
          showImage={addonGroup.showImage}
          handleContextAction={handleCheckboxOption}
          hasQuantity={addonGroup.hasQuantity}
        />
      ) : (
        <RadioAddonOptions
          addonGroupId={addonGroup.CONTENTFUL_ID}
          options={addonGroup.addons ?? []}
          showImage={addonGroup.showImage}
          handleContextAction={handleCheckboxOption}
          hasQuantity={addonGroup.hasQuantity}
        />
      )}
    </MlProductOptionsCollapse>
  )
}
