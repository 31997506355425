import { memo, useEffect, useMemo, useState } from 'react'
import { MlSwatchCard } from '@curran-catalog/curran-atomic-library'

import { FurnitureProductActionTypes, ObjFrameSlingColState } from 'context'
import { useFurnitureProduct, useMediaQuery } from 'hooks'
import { ComboLength } from '@components/product-detail-collapses'
import { formatPrice, handleComboHierarchy, highlightOption, isValidUrl, normalizeAsset, renderOptionName } from 'utils'
import { ContentOptionPopover, OptionPopover } from './option-popover'
import { PopoverWrapper } from '@components/PopoverWrapper'

const getCheckedFrameSlingCol = ({
  options,
  hierarchy,
  primaryComboFrameSlingCol,
  secondaryComboFrameSlingCol,
  tertiaryComboFrameSlingCol,
  quaternaryComboFrameSlingCol,
  openPopover,
}: {
  openPopover: boolean
  hierarchy: ComboLength
  options: ObjFrameSlingColState[]
  primaryComboFrameSlingCol?: ObjFrameSlingColState
  secondaryComboFrameSlingCol?: ObjFrameSlingColState
  tertiaryComboFrameSlingCol?: ObjFrameSlingColState
  quaternaryComboFrameSlingCol?: ObjFrameSlingColState
}) => {
  switch (hierarchy) {
    case ComboLength.PRIMARY: {
      const frameSlingColIndex = openPopover
        ? options.findIndex(({ CONTENTFUL_ID }) => CONTENTFUL_ID === primaryComboFrameSlingCol?.CONTENTFUL_ID)
        : undefined
      // Convert the primaryComboFrameSlingCol checked to popover obj
      const popoverFrameSlingColObj: ObjFrameSlingColState | undefined = primaryComboFrameSlingCol
        ? {
            ...primaryComboFrameSlingCol,
            CONTENTFUL_ID: `${primaryComboFrameSlingCol?.CONTENTFUL_ID}-popover`,
            isPopover: true,
          }
        : undefined

      return { frameSlingColIndex, popoverFrameSlingColObj }
    }

    case ComboLength.SECONDARY: {
      const frameSlingColIndex = openPopover
        ? options.findIndex(({ CONTENTFUL_ID }) => CONTENTFUL_ID === secondaryComboFrameSlingCol?.CONTENTFUL_ID)
        : undefined
      // Convert the secondaryComboFrameSlingCol checked to popover obj
      const popoverFrameSlingColObj: ObjFrameSlingColState | undefined = secondaryComboFrameSlingCol
        ? {
            ...secondaryComboFrameSlingCol,
            CONTENTFUL_ID: `${secondaryComboFrameSlingCol?.CONTENTFUL_ID}-popover`,
            isPopover: true,
          }
        : undefined

      return { frameSlingColIndex, popoverFrameSlingColObj }
    }

    case ComboLength.TERTIARY: {
      const frameSlingColIndex = openPopover
        ? options.findIndex(({ CONTENTFUL_ID }) => CONTENTFUL_ID === tertiaryComboFrameSlingCol?.CONTENTFUL_ID)
        : undefined
      // Convert the tertiaryComboFrameSlingCol checked to popover obj
      const popoverFrameSlingColObj: ObjFrameSlingColState | undefined = tertiaryComboFrameSlingCol
        ? {
            ...tertiaryComboFrameSlingCol,
            CONTENTFUL_ID: `${tertiaryComboFrameSlingCol?.CONTENTFUL_ID}-popover`,
            isPopover: true,
          }
        : undefined

      return { frameSlingColIndex, popoverFrameSlingColObj }
    }
    case ComboLength.QUATERNARY: {
      const frameSlingColIndex = openPopover
        ? options.findIndex(({ CONTENTFUL_ID }) => CONTENTFUL_ID === quaternaryComboFrameSlingCol?.CONTENTFUL_ID)
        : undefined
      // Convert the quaternaryComboFrameSlingCol checked to popover obj
      const popoverFrameSlingColObj: ObjFrameSlingColState | undefined = quaternaryComboFrameSlingCol
        ? {
            ...quaternaryComboFrameSlingCol,
            CONTENTFUL_ID: `${quaternaryComboFrameSlingCol?.CONTENTFUL_ID}-popover`,
            isPopover: true,
          }
        : undefined

      return { frameSlingColIndex, popoverFrameSlingColObj }
    }
    default:
      return { frameSlingColIndex: undefined, popoverFrameSlingColObj: undefined }
  }
}

export const isComboFrameSlingColDisabled = ({
  hierarchy,
  parentPrimaryId,
  primaryComboFrameSlingColId,
  parentSecondaryId,
  secondaryComboFrameSlingColId,
  parentTertiaryId,
  tertiaryComboFrameSlingColId,
}: {
  hierarchy: number
  parentPrimaryId?: string | null
  primaryComboFrameSlingColId?: string
  parentSecondaryId?: string | null
  secondaryComboFrameSlingColId?: string
  parentTertiaryId?: string | null
  tertiaryComboFrameSlingColId?: string
}) => {
  switch (hierarchy) {
    case ComboLength.SECONDARY:
      return parentPrimaryId && primaryComboFrameSlingColId && parentPrimaryId === primaryComboFrameSlingColId
    case ComboLength.TERTIARY:
      return parentSecondaryId && secondaryComboFrameSlingColId && parentSecondaryId === secondaryComboFrameSlingColId
    case ComboLength.QUATERNARY:
      return parentTertiaryId && tertiaryComboFrameSlingColId && parentTertiaryId === tertiaryComboFrameSlingColId
    default:
      return false
  }
}

interface ColorOptionProps {
  hierarchy: ComboLength
  option: ObjFrameSlingColState
  hasSelectedOption: boolean
  useGroupedOptions?: boolean
  onOpenPopover: () => void
  onClosePopover: () => void
}

const ComboColorOption = memo(
  ({ option, hierarchy, hasSelectedOption, useGroupedOptions, onOpenPopover, onClosePopover }: ColorOptionProps) => {
    const { state, dispatch } = useFurnitureProduct()
    const { primaryComboFrameSlingCol, secondaryComboFrameSlingCol, tertiaryComboFrameSlingCol, productsDisabled } =
      state

    const { images, subHeader, isPopover = false, description, price } = option

    const isDesktop = useMediaQuery('(min-width: 1280px)')

    const [isOpenModal, setIsOpenModal] = useState(false)

    const isColorDisabled = useMemo(
      () =>
        productsDisabled
          ? true
          : !useGroupedOptions && hierarchy >= ComboLength.SECONDARY
          ? !isComboFrameSlingColDisabled({
              hierarchy,
              parentPrimaryId: option?.parentPrimaryId,
              primaryComboFrameSlingColId: primaryComboFrameSlingCol?.CONTENTFUL_ID,
              parentSecondaryId: option?.parentSecondaryId,
              secondaryComboFrameSlingColId: secondaryComboFrameSlingCol?.CONTENTFUL_ID,
              parentTertiaryId: option?.parentTertiaryId,
              tertiaryComboFrameSlingColId: tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
            })
          : false,
      [
        productsDisabled,
        hierarchy,
        option?.parentPrimaryId,
        option?.parentSecondaryId,
        option?.parentTertiaryId,
        primaryComboFrameSlingCol?.CONTENTFUL_ID,
        secondaryComboFrameSlingCol?.CONTENTFUL_ID,
        tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
        useGroupedOptions,
      ],
    )

    const hasImages = Array.isArray(images) && images.length > 0

    return (
      <li
        className={`w-fit justify-self-center ${isPopover ? 'col-span-2' : ''} ${isColorDisabled ? 'opacity-75' : ''}`}
        onClick={() =>
          dispatch({ type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS, isClearSelectionDisabled: false })
        }
      >
        {isPopover ? (
          <OptionPopover onClose={() => onClosePopover()}>
            <ContentOptionPopover
              price={price ? formatPrice(price) : ''}
              name={renderOptionName(option)}
              description={description}
              images={images}
            />
          </OptionPopover>
        ) : (
          <div onMouseEnter={() => setIsOpenModal(true)} onMouseLeave={() => setIsOpenModal(false)}>
            <MlSwatchCard
              id={option.CONTENTFUL_ID}
              selected={hasSelectedOption}
              disabled={isColorDisabled}
              title={renderOptionName(option)}
              description={subHeader ?? ''}
              image={
                hasImages && isValidUrl(images[0].secure_url)
                  ? normalizeAsset({ asset: images[0] })
                  : { src: '/images/image-coming-soon.jpg', alt: '' }
              }
              clickHandler={() => {
                handleComboHierarchy(
                  hierarchy,
                  () => {
                    dispatch({
                      type: FurnitureProductActionTypes.SET_PRIMARY_COMBO,
                      primaryComboFrameSlingCol: option,
                    })

                    if (!useGroupedOptions && primaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                      dispatch({
                        type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
                        secondaryComboFrameSlingCol: undefined,
                      })
                      dispatch({
                        type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                        tertiaryComboFrameSlingCol: undefined,
                      })
                      dispatch({
                        type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                        quaternaryComboFrameSlingCol: undefined,
                      })
                    }

                    onOpenPopover()
                  },
                  () => {
                    dispatch({
                      type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
                      secondaryComboFrameSlingCol: option,
                    })
                    if (!useGroupedOptions && secondaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                      dispatch({
                        type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                        tertiaryComboFrameSlingCol: undefined,
                      })
                      dispatch({
                        type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                        quaternaryComboFrameSlingCol: undefined,
                      })
                    }
                    onOpenPopover()
                  },
                  () => {
                    dispatch({
                      type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
                      tertiaryComboFrameSlingCol: option,
                    })
                    if (!useGroupedOptions && tertiaryComboFrameSlingCol?.CONTENTFUL_ID !== option.CONTENTFUL_ID) {
                      dispatch({
                        type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                        quaternaryComboFrameSlingCol: undefined,
                      })
                    }

                    onOpenPopover()
                  },
                  () => {
                    dispatch({
                      type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
                      quaternaryComboFrameSlingCol: option,
                    })
                    onOpenPopover()
                  },
                )

                dispatch({
                  type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
                })
              }}
            />
            {isDesktop && !isColorDisabled && (
              <PopoverWrapper openModal={isOpenModal}>
                <ContentOptionPopover
                  price={price ? formatPrice(price) : ''}
                  name={renderOptionName(option)}
                  description={description}
                  images={images}
                />
              </PopoverWrapper>
            )}
          </div>
        )}
      </li>
    )
  },
)

interface ColorOptionsProps {
  hierarchy: number
  options: ObjFrameSlingColState[]
  useGroupedOptions?: boolean
}

export const ComboColorOptions = memo(({ hierarchy, options = [], useGroupedOptions }: ColorOptionsProps) => {
  const { state, dispatch } = useFurnitureProduct()
  const {
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
  } = state

  const isDesktop = useMediaQuery('(min-width: 1280px)')

  const [openPopover, setOpenPopover] = useState(false)

  useEffect(() => {
    if (hierarchy === ComboLength.PRIMARY) {
      dispatch({
        type: FurnitureProductActionTypes.SET_PRIMARY_COMBO,
        primaryComboFrameSlingCol: options[0],
      })
      dispatch({
        type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchy])

  useEffect(() => {
    if (hierarchy === ComboLength.SECONDARY) {
      if (!useGroupedOptions) {
        if (primaryComboFrameSlingCol?.CONTENTFUL_ID) {
          const filteredOptions = options.filter(
            (filterOption) => filterOption?.parentPrimaryId === primaryComboFrameSlingCol?.CONTENTFUL_ID,
          )

          dispatch({
            type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
            secondaryComboFrameSlingCol: filteredOptions[0],
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        }
      } else {
        dispatch({
          type: FurnitureProductActionTypes.SET_SECONDARY_COMBO,
          secondaryComboFrameSlingCol: options[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useGroupedOptions, hierarchy, primaryComboFrameSlingCol?.CONTENTFUL_ID])

  useEffect(() => {
    if (hierarchy === ComboLength.TERTIARY) {
      if (!useGroupedOptions) {
        if (primaryComboFrameSlingCol?.CONTENTFUL_ID && secondaryComboFrameSlingCol?.CONTENTFUL_ID) {
          const filteredOptions = options.filter(
            (filterOption) => filterOption?.parentSecondaryId === secondaryComboFrameSlingCol?.CONTENTFUL_ID,
          )

          dispatch({
            type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
            tertiaryComboFrameSlingCol: filteredOptions[0],
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        }
      } else {
        dispatch({
          type: FurnitureProductActionTypes.SET_TERTIARY_COMBO,
          tertiaryComboFrameSlingCol: options[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryComboFrameSlingCol?.CONTENTFUL_ID,
    secondaryComboFrameSlingCol?.CONTENTFUL_ID,
    useGroupedOptions,
    hierarchy,
  ])

  useEffect(() => {
    if (hierarchy === ComboLength.QUATERNARY) {
      if (!useGroupedOptions) {
        if (
          primaryComboFrameSlingCol?.CONTENTFUL_ID &&
          secondaryComboFrameSlingCol?.CONTENTFUL_ID &&
          tertiaryComboFrameSlingCol?.CONTENTFUL_ID
        ) {
          const filteredOptions = options.filter(
            (filterOption) => filterOption?.parentTertiaryId === tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
          )

          dispatch({
            type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
            quaternaryComboFrameSlingCol: filteredOptions[0],
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        }
      } else {
        dispatch({
          type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO,
          quaternaryComboFrameSlingCol: options[0],
        })
        dispatch({
          type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryComboFrameSlingCol?.CONTENTFUL_ID,
    secondaryComboFrameSlingCol?.CONTENTFUL_ID,
    tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
    useGroupedOptions,
    hierarchy,
  ])

  const { frameSlingColIndex, popoverFrameSlingColObj } = useMemo(
    () =>
      getCheckedFrameSlingCol({
        openPopover,
        primaryComboFrameSlingCol,
        secondaryComboFrameSlingCol,
        tertiaryComboFrameSlingCol,
        quaternaryComboFrameSlingCol,
        hierarchy,
        options,
      }),
    [
      hierarchy,
      openPopover,
      options,
      primaryComboFrameSlingCol,
      quaternaryComboFrameSlingCol,
      secondaryComboFrameSlingCol,
      tertiaryComboFrameSlingCol,
    ],
  )

  return (
    <ul className="grid grid-cols-2 items-center xl:grid-cols-3 gap-4 p-4 w-full max-h-[460px] md:max-h-96 xl:max-h-[500px] overflow-y-scroll">
      {(typeof frameSlingColIndex === 'number' && popoverFrameSlingColObj && !isDesktop
        ? [
            ...options.slice(0, frameSlingColIndex % 2 === 0 ? frameSlingColIndex : frameSlingColIndex - 1),
            popoverFrameSlingColObj,
            ...options.slice(frameSlingColIndex % 2 === 0 ? frameSlingColIndex : frameSlingColIndex - 1),
          ].filter(Boolean)
        : options
      ).map((option, index) => {
        const { CONTENTFUL_ID } = option
        const hasSelectedOption = highlightOption(
          hierarchy,
          CONTENTFUL_ID,
          primaryComboFrameSlingCol?.CONTENTFUL_ID,
          secondaryComboFrameSlingCol?.CONTENTFUL_ID,
          tertiaryComboFrameSlingCol?.CONTENTFUL_ID,
          quaternaryComboFrameSlingCol?.CONTENTFUL_ID,
        )

        return (
          <ComboColorOption
            key={`combo-color-${index}-${CONTENTFUL_ID}`}
            hierarchy={hierarchy}
            option={option}
            useGroupedOptions={useGroupedOptions}
            hasSelectedOption={hasSelectedOption}
            onOpenPopover={() => setOpenPopover(true)}
            onClosePopover={() => setOpenPopover(false)}
          />
        )
      })}
    </ul>
  )
})
